import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import { APIBase } from 'api/hosts';
import React, { useContext, useEffect, useState } from 'react';
import { FaCrown, FaHeart, FaCog } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const TVScreenDisplay: React.FC = () => {
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();

  const [videoUrl, setVideoUrl] = useState<string>('');
  const [rooms, setRooms] = useState<any[]>([]);
  const [playlists, setPlaylists] = useState<any[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<string>('');
  const [selectedPlaylist, setSelectedPlaylist] = useState<string>('');
  const [playlist, setPlaylist] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<any>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const translations = {
    english: {
      back: 'Back',
      videoPlaceholder: 'Enter YouTube Video URL',
      selectRoom: 'Select Room',
      selectPlaylist: 'Select Playlist',
      loading: 'Loading...',
      noRooms: 'No rooms available.',
      noPlaylists: 'No playlists available.',
      leaderboard: 'Leaderboard',
      noParticipants: 'No participants yet.',
      settings: 'Settings',
      save: 'Save',
      close: 'Close',
      contestTitle: 'Karaoke Contest!',
      roomMessage: 'Contest is inside the ',
      noRoomSelected: 'room (please select a room)',
    },
    spanish: {
      back: 'Volver',
      videoPlaceholder: 'Introduzca la URL del video de YouTube',
      selectRoom: 'Seleccionar sala',
      selectPlaylist: 'Seleccionar lista de reproducción',
      loading: 'Cargando...',
      noRooms: 'No hay salas disponibles.',
      noPlaylists: 'No hay listas de reproducción disponibles.',
      leaderboard: 'Clasificación',
      noParticipants: 'Aún no hay participantes.',
      settings: 'Configuraciones',
      save: 'Guardar',
      close: 'Cerrar',
      contestTitle: '¡Concurso de Karaoke!',
      roomMessage: 'El concurso está dentro de ',
      noRoomSelected: 'la sala (por favor seleccione una sala)',
    },
    polish: {
      back: 'Powrót',
      videoPlaceholder: 'Wprowadź URL wideo z YouTube',
      selectRoom: 'Wybierz pokój',
      selectPlaylist: 'Wybierz listę odtwarzania',
      loading: 'Ładowanie...',
      noRooms: 'Brak dostępnych pokoi.',
      noPlaylists: 'Brak dostępnych list odtwarzania.',
      leaderboard: 'Tablica wyników',
      noParticipants: 'Brak uczestników.',
      settings: 'Ustawienia',
      save: 'Zapisz',
      close: 'Zamknij',
      contestTitle: 'Karaoke Konkurs!',
      roomMessage: 'Konkurs odbywa się w ',
      noRoomSelected: 'pokoju (proszę wybrać pokój)',
    },
  };

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchUserProfile();
    fetchRooms();
  }, []);

  useEffect(() => {
    if (selectedRoom) {
      fetchPlaylists(selectedRoom);
    }
  }, [selectedRoom]);

  useEffect(() => {
    if (selectedPlaylist) {
      fetchPlaylistDetails(selectedPlaylist);
    }
  }, [selectedPlaylist]);

  const fetchPlaylistDetails = async (selectedPlaylist: any) => {
    try {
      const response = await fetch(`${APIBase}/client/playlist/${selectedPlaylist}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPlaylist(data.playlist);
      } else {
        throw new Error('Error');
      }
    } catch {
      Swal.fire({
        title: 'Error',
        text: 'Error',
        icon: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchUserProfile = async () => {
    try {
      const response = await fetch(`${APIBase}/client/user`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${currentToken}`, 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      } else {
        throw new Error('Failed to fetch user profile');
      }
    } catch (error) {
      Swal.fire({ title: 'error fetching user', icon: 'error' });
    }
  };

  const fetchRooms = async () => {
    try {
      const response = await fetch(`${APIBase}/client/room`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setRooms(data.rooms);
      } else {
        throw new Error('Failed to fetch rooms');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: t.noRooms,
        icon: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchPlaylists = async (roomId: string) => {
    try {
      setLoading(true);
      const response = await fetch(`${APIBase}/client/playlist/byRoomId/${roomId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPlaylists(data.playlists);
      } else {
        throw new Error('Failed to fetch playlists');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: t.noPlaylists,
        icon: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const leaderboard = playlist?.participants.map((participant: any) => {
    const likesForParticipant = playlist?.userInteractions.filter(
      (interaction: any) => interaction.type === 'like' && interaction.recipient === participant._id,
    );

    return {
      ...participant,
      likes: likesForParticipant || [],
    };
  });

  const selectedRoomName = rooms.find((room) => room._id === selectedRoom)?.name || t.noRoomSelected;

  return (
    <div className="relative w-full h-screen text-white">
      {/* Background Video */}
      {videoUrl && (
        <iframe
          src={`https://www.youtube.com/embed/${new URL(videoUrl).searchParams.get(
            'v',
          )}?autoplay=1&loop=1&playlist=${new URL(videoUrl).searchParams.get('v')}`}
          className="absolute inset-0 w-full h-full object-cover"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      )}

      {/* Overlay Content */}
      <div className="absolute right-0 top-0 h-full w-1/8 bg-black bg-opacity-75 p-4 flex flex-col space-y-4 pt-24">
        {/* Contest Title */}
        <h3 className="text-2xl font-extrabold text-center text-yellow-400">{t.contestTitle}</h3>
        <p className="text-sm text-center text-gray-300">{`${t.roomMessage} ${selectedRoomName}`}</p>

        {/* Leaderboard */}
        <div className="flex-1 overflow-y-auto bg-gray-800 bg-opacity-30 p-4 rounded-md">
          <h3 className="text-lg font-bold mb-3 flex items-center">
            <FaCrown className="mr-2 text-yellow-400" /> {t.leaderboard}
          </h3>
          {leaderboard
            ?.sort((a: any, b: any) => (b.likes?.length || 0) - (a.likes?.length || 0))
            .map((participant: any, index: number) => {
              const isTopThree = index < 3;

              return (
                <div
                  key={participant._id}
                  className={`flex items-center space-x-4 p-4 py-2 rounded-lg shadow-lg transition-all duration-300 mb-2 ${
                    isTopThree
                      ? index === 0
                        ? 'bg-gradient-to-r from-purple-400 via-blue-400 to-teal-300 text-gray-900'
                        : index === 1
                        ? 'bg-gradient-to-r from-blue-300 via-teal-200 to-gray-300 text-gray-900'
                        : 'bg-gradient-to-r from-teal-200 via-gray-300 to-gold-300 text-gray-900'
                      : 'bg-gray-800 bg-opacity-30/60 text-gray-300'
                  } hover:scale-105`}
                  style={isTopThree ? { boxShadow: '0 0 15px rgba(255, 255, 255, 0.2)' } : {}}
                >
                  <div
                    className={`w-12 h-12 shrink-0 flex items-center justify-center rounded-full text-xl font-bold ${
                      isTopThree ? 'bg-white text-gray-900 shadow-lg' : 'bg-gray-700 text-gray-300'
                    }`}
                  >
                    {index + 1}
                  </div>
                  <div className="text-sm font-medium truncate w-full">{participant.username || 'Unknown'}</div>
                  <div className="relative flex items-center justify-center ml-auto h-8 w-8">
                    <FaHeart className="text-pink-400 text-xl h-8 w-8" />
                    <span className="absolute text-xs text-white">{participant.likes?.length || 0}</span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* Settings Icon */}
      <div
        className="absolute bottom-5 right-5 p-3 bg-gray-800 bg-opacity-30 text-white rounded-full shadow-lg cursor-pointer hover:bg-gray-700 transition"
        onClick={() => setIsModalOpen(true)}
      >
        <FaCog />
      </div>

      {/* Settings Modal */}
      {isModalOpen && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-800 bg-opacity-30 p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-lg font-bold mb-4">{t.settings}</h3>

            <input
              type="text"
              value={videoUrl}
              onChange={(e) => setVideoUrl(e.target.value)}
              placeholder={t.videoPlaceholder}
              className="p-3 bg-gray-700 text-white rounded-md w-full mb-4"
            />

            <select
              value={selectedRoom}
              onChange={(e) => setSelectedRoom(e.target.value)}
              className="p-3 bg-gray-700 text-white rounded-md w-full mb-4"
            >
              <option value="" disabled>
                {t.selectRoom}
              </option>
              {rooms.map((room) => (
                <option key={room._id} value={room._id}>
                  {room.name}
                </option>
              ))}
            </select>

            <select
              value={selectedPlaylist}
              onChange={(e) => setSelectedPlaylist(e.target.value)}
              className="p-3 bg-gray-700 text-white rounded-md w-full mb-4"
            >
              <option value="" disabled>
                {t.selectPlaylist}
              </option>
              {playlists.map((playlist) => (
                <option key={playlist._id} value={playlist._id}>
                  {playlist.name}
                </option>
              ))}
            </select>

            <div className="flex justify-end space-x-4">
              <button
                className="p-2 bg-red-600 text-white rounded-md hover:bg-red-500 transition"
                onClick={() => setIsModalOpen(false)}
              >
                {t.close}
              </button>
              <button
                className="p-2 bg-green-600 text-white rounded-md hover:bg-green-500 transition"
                onClick={() => setIsModalOpen(false)}
              >
                {t.save}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TVScreenDisplay;
