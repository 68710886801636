import { AuthContext } from 'Contexts/Auth'; // To get the current token
import { APIBase } from 'api/hosts'; // API base URL
import { useContext, useState } from 'react';
import { QrReader } from 'react-qr-reader'; // For scanning QR codes
import { useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit'; // Loading spinner
import Swal from 'sweetalert2';

const ScanDiscount = () => {
  const [scanning, setScanning] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cameraError, setCameraError] = useState(false); // Handle camera errors
  const [scannedData, setScannedData] = useState(null); // Store detected QR data for manual confirmation
  const { currentToken } = useContext(AuthContext);
  const navigate = useNavigate();

  // Process the scanned QR code
  // Inside processScan function
  const processScan = async () => {
    setLoading(true); // Start loading spinner
  
    try {
      const { venueId, activityId, pointsUsed, userId } = scannedData;
  
      const response = await fetch(`${APIBase}/client/activity/redeem`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          venueId,
          activityId,
          pointsEarned: pointsUsed, // Points earned for the activity
          userId,
        }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        Swal.fire({
          title: 'Success!',
          text: `You earned ${pointsUsed} points for the activity!`,
          icon: 'success',
          timer: 2000,
        }).then(() => navigate('/app/admin/scan-discount'));
      } else {
        switch (result.code) {
          case 'ALREADY_REDEEMED':
            Swal.fire({
              title: 'Already Redeemed',
              text: result.message,
              icon: 'info',
              timer: 3000,
            });
            break;
  
          case 'USER_NOT_FOUND':
            Swal.fire({
              title: 'Error',
              text: 'User not found. Please log in again.',
              icon: 'error',
              timer: 3000,
            });
            break;
  
          default:
            Swal.fire({
              title: 'Error',
              text: result.message || 'Failed to process activity. Please try again later.',
              icon: 'error',
              timer: 3000,
            });
        }
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message || 'Failed to scan QR code. Please try again later.',
        icon: 'error',
        timer: 3000,
      });
    } finally {
      setLoading(false); // Stop loading spinner
      setScanning(false); // Stop scanning after success or error
    }
  };
  

  const handleError = (err) => {
    setError('An error occurred during scanning. Please try again.');
    setCameraError(true); // Set the camera error state if there is an issue with the camera
    console.error(err);
  };

  const previewStyle = {
    height: 320,
    width: 320,
    borderRadius: '10px',
    overflow: 'hidden',
    border: '2px solid #86ff0d',
  };

  return (
    <div className="flex flex-col items-center justify-start h-screen bg-gray-900 px-4 pt-28">
      {loading ? (
        <div className="flex flex-col items-center justify-center">
          <StageSpinner size={60} color="#86ff0d" />
          <p className="text-white mt-4">Processing QR Code...</p>
        </div>
      ) : scanning ? (
        <>
          <h2 className="text-2xl text-white mb-4">Scan Discount or Activity QR Code</h2>
          <div className="relative w-full h-56">
            {!cameraError ? (
              <QrReader
                delay={300}
                constraints={{ facingMode: 'environment' }} // Use the default camera, typically the back camera
                style={previewStyle}
                onError={handleError}
                onResult={(result) => {
                  if (result) {
                    const data = JSON.parse(result.text);
                    setScannedData(data);
                    setScanning(false);
                  }
                }}
              />
            ) : (
              <p className="text-red-500">Camera access denied or not available. Please check your permissions.</p>
            )}
          </div>
          {error && <p className="text-red-500 mt-4">{error}</p>}
        </>
      ) : (
        <div className="text-white">
          {scannedData ? (
            <>
              <h3 className="text-xl mb-4">QR Code Detected!</h3>
              <p>
                <strong>Venue ID:</strong> {scannedData.venueId}
              </p>
              {scannedData.activityId ? (
                <>
                  <p>
                    <strong>Activity ID:</strong> {scannedData.activityId}
                  </p>
                  <p>
                    <strong>Points Earned:</strong> {scannedData.pointsUsed}
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <strong>Menu Item ID:</strong> {scannedData.menuItemId}
                  </p>
                  <p>
                    <strong>Points Used:</strong> {scannedData.pointsUsed}
                  </p>
                  <p>
                    <strong>Price with Discount:</strong> {scannedData.priceWithDiscount}
                  </p>
                </>
              )}
              <p>
                <strong>User ID:</strong> {scannedData.userId}
              </p>

              <button
                className="mt-6 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                onClick={processScan}
              >
                {scannedData.activityId ? 'Add Points for Activity' : 'Redeem Discount'}
              </button>
              <button
                className="mt-6 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 ml-4"
                onClick={() => setScanning(true)} // Allow to re-scan
              >
                Re-scan QR Code
              </button>
            </>
          ) : (
            <p>No valid QR code detected yet.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default ScanDiscount;
