import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import { APIBase } from 'api/hosts';
import React, { useContext, useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaMusic, FaPlus, FaQrcode, FaSpinner } from 'react-icons/fa';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const extractVideoId = (url: string): string | null => {
  try {
    const urlObj = new URL(url);

    if (urlObj.hostname.includes('youtube.com')) {
      return urlObj.searchParams.get('v'); // Extract 'v' parameter for standard YouTube URLs
    }

    if (urlObj.hostname.includes('youtu.be')) {
      return urlObj.pathname.substring(1).split('?')[0]; // Extract video ID from shortened URLs
    }

    return null; // Unsupported format
  } catch (error) {
    console.error('Invalid URL:', url);
    return null;
  }
};

const PlaylistDetail: React.FC = () => {
  const { playlistId } = useParams<{ playlistId: string }>();
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage(); // Language context
  const [playlist, setPlaylist] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [addVideoLoading, setAddVideoLoading] = useState<boolean>(false);
  const [newVideo, setNewVideo] = useState({ videoId: '' });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [qrModalOpen, setQrModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const [activeUser, setActiveUser] = useState<{ username: string; id: string } | null>(null); // State for cycling user
  const [winner, setWinner] = useState<{ username: string; id: string } | null>(null); // Final winner
  const [isCycling, setIsCycling] = useState<boolean>(false);
  const translations = {
    english: {
      nowPlaying: 'Now Playing',
      addedBy: 'Added by',
      unknown: 'Unknown',
      upNext: 'Up Next',
      following: 'Following',
      noVideos: 'No videos in the playlist.',
      playlistNotFound: 'Playlist not found.',
      loading: 'Loading...',
      addVideo: 'Add New Video',
      videoTitle: 'Video Title',
      videoUrl: 'Video URL',
      adding: 'Adding...',
      add: 'Add Video',
      cancel: 'Cancel',
      qrTitle: 'Scan to Open Playlist',
      close: 'Close',
      success: 'Success',
      videoAdded: 'Video added successfully.',
      fetchError: 'Could not fetch playlist details.',
      invalidUrl: 'Invalid YouTube URL.',
      fieldsRequired: 'Both video URL and title are required.',
      addVideoError: 'Could not add video to the playlist.',
      noUpcoming: 'No upcoming video.',
      remainingVideos: 'No remaining videos in the playlist.',
      error: 'Error',
      randomUser: 'Spin the Wheel!',
      selectedUser: 'And the Winner Is...',
      cycling: 'Cycling through users...',
    },
    spanish: {
      nowPlaying: 'Reproduciendo Ahora',
      addedBy: 'Añadido por',
      unknown: 'Desconocido',
      upNext: 'A Continuación',
      following: 'Siguientes',
      noVideos: 'No hay videos en la lista de reproducción.',
      playlistNotFound: 'Lista de reproducción no encontrada.',
      loading: 'Cargando...',
      addVideo: 'Añadir Nuevo Video',
      videoTitle: 'Título del Video',
      videoUrl: 'URL del Video',
      adding: 'Añadiendo...',
      add: 'Añadir Video',
      cancel: 'Cancelar',
      qrTitle: 'Escanea para Abrir la Lista',
      close: 'Cerrar',
      success: 'Éxito',
      videoAdded: 'Video añadido con éxito.',
      fetchError: 'No se pudo cargar la lista de reproducción.',
      invalidUrl: 'URL de YouTube inválida.',
      fieldsRequired: 'Se requieren tanto la URL del video como el título.',
      addVideoError: 'No se pudo añadir el video a la lista de reproducción.',
      noUpcoming: 'No hay videos siguientes.',
      remainingVideos: 'No hay videos restantes en la lista de reproducción.',
      error: 'Error',
      randomUser: '¡Gira la Ruleta!',
      selectedUser: 'Y el Ganador Es...',
      cycling: 'Girando entre usuarios...',
    },
    polish: {
      nowPlaying: 'Teraz Odtwarzane',
      addedBy: 'Dodane przez',
      unknown: 'Nieznany',
      upNext: 'Dalej',
      following: 'Następne',
      noVideos: 'Brak wideo na liście odtwarzania.',
      playlistNotFound: 'Lista odtwarzania nie znaleziona.',
      loading: 'Ładowanie...',
      addVideo: 'Dodaj Nowe Wideo',
      videoTitle: 'Tytuł Wideo',
      videoUrl: 'URL Wideo',
      adding: 'Dodawanie...',
      add: 'Dodaj Wideo',
      cancel: 'Anuluj',
      qrTitle: 'Zeskanuj, aby otworzyć listę odtwarzania',
      close: 'Zamknij',
      success: 'Sukces',
      videoAdded: 'Wideo pomyślnie dodane.',
      fetchError: 'Nie udało się załadować listy odtwarzania.',
      invalidUrl: 'Nieprawidłowy URL YouTube.',
      fieldsRequired: 'Wymagany jest zarówno URL wideo, jak i tytuł.',
      addVideoError: 'Nie udało się dodać wideo do listy odtwarzania.',
      noUpcoming: 'Brak kolejnych wideo.',
      remainingVideos: 'Brak pozostałych wideo na liście odtwarzania.',
      error: 'Błąd',
      randomUser: 'Zakręć Kołem!',
      selectedUser: 'A Zwycięzcą Jest...',
      cycling: 'Przeglądam użytkowników...',
    },
  };

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    if (playlistId) {
      fetchPlaylistDetails();
    }
    const interval = setInterval(fetchPlaylistDetails, 10000); // Fetch playlist every 10 seconds
    return () => clearInterval(interval);
  }, [playlistId]);

  const fetchPlaylistDetails = async () => {
    try {
      const response = await fetch(`${APIBase}/client/playlist/${playlistId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPlaylist(data.playlist);
      } else {
        throw new Error(t.fetchError);
      }
    } catch {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const updateCurrentVideoIndex = async (index: number) => {
    try {
      const response = await fetch(`${APIBase}/client/playlist/${playlistId}/updateCurrentVideoIndex`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ currentVideoIndex: index }),
      });

      if (!response.ok) {
        console.error(`Failed to update current video index. Status: ${response.status}`);
      }
      fetchPlaylistDetails();
    } catch (error) {
      console.error('Error updating current video index:', error);
    }
  };

  const handleAddVideo = async () => {
    const { videoId: videoUrl } = newVideo;

    if (!videoUrl) {
      Swal.fire({
        title: t.error,
        text: t.fieldsRequired,
        icon: 'error',
      });
      return;
    }

    const videoId = extractVideoId(videoUrl);

    if (!videoId) {
      Swal.fire({
        title: t.error,
        text: t.invalidUrl,
        icon: 'error',
      });
      return;
    }

    setAddVideoLoading(true);
    try {
      const response = await fetch(`${APIBase}/client/playlist/${playlistId}/addVideo`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ videoId, playlistId }),
      });

      if (response.ok) {
        Swal.fire({
          title: t.success,
          text: t.videoAdded,
          icon: 'success',
        });
        fetchPlaylistDetails();
        setNewVideo({ videoId: ''});
        setModalOpen(false);
      } else {
        throw new Error(t.addVideoError);
      }
    } catch {
      Swal.fire({
        title: t.error,
        text: t.addVideoError,
        icon: 'error',
      });
    } finally {
      setAddVideoLoading(false);
    }
  };

  const jumpToVideo = (index: number) => {
    updateCurrentVideoIndex(index);
    fetchPlaylistDetails();
  };

  const startCyclingUsers = () => {
    if (playlist && playlist.videos.length > 0) {
      const users = playlist.videos
        .map((video: any) => video.addedBy)
        .filter((user: any) => user?.username && user?._id); // Filter valid users
      if (users.length > 0) {
        setIsCycling(true);
        let intervalId: NodeJS.Timeout;

        // Start cycling through users
        intervalId = setInterval(() => {
          const randomUser = users[Math.floor(Math.random() * users.length)];
          setActiveUser({ username: randomUser.username, id: randomUser._id });
        }, 100); // Change user every 100ms

        // Stop cycling after 10 seconds
        setTimeout(() => {
          clearInterval(intervalId);
          const finalWinner = users[Math.floor(Math.random() * users.length)];
          setActiveUser(null);
          setWinner({ username: finalWinner.username, id: finalWinner._id });
          setIsCycling(false);
        }, 10000);
      }
    }
  };

  const currentVideo = playlist?.videos[playlist.currentVideoIndex] || null;
  const nextVideo = playlist?.videos[playlist.currentVideoIndex + 1] || null;
  const remainingVideos = playlist?.videos.slice(playlist.currentVideoIndex + 2) || [];
  const currentUrl = window.location.href;

  if (loading) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <FaSpinner className="animate-spin text-4xl text-gray-400" />
        <p>{t.loading}</p>
      </div>
    );
  }

  if (!playlist) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <p>{t.playlistNotFound}</p>
      </div>
    );
  }

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 flex flex-col lg:flex-row lg:space-x-4 py-20 relative">
      {/* Left Section */}
      <div className="lg:w-1/2 space-y-4">
        <div className="relative w-full h-20">
          <button
            onClick={() => navigate(-1)}
            className="absolute top-4 left-4 bg-gray-700 text-white p-2 rounded-full hover:bg-gray-600 transition w-12 h-12 flex items-center justify-center"
          >
            <FaArrowLeft className="text-xl" />
          </button>
          <button
            onClick={() => setQrModalOpen(true)}
            className="absolute top-4 right-20 bg-blue-600 text-white p-2 rounded-full hover:bg-blue-500 transition w-12 h-12 flex items-center justify-center lg:hidden"
          >
            <FaQrcode className="text-xl" />
          </button>
          <button
            onClick={() => setModalOpen(true)}
            className="absolute top-4 right-4 bg-green-600 text-white p-2 rounded-full flex items-center justify-center hover:bg-green-500 transition w-12 h-12 flex-shrink-0  lg:hidden"
          >
            <div className="relative">
              <FaMusic className="text-xl" />
              <FaPlus className="absolute text-xs text-white top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-green-600 rounded-full" />
            </div>
          </button>
        </div>

        <div className="bg-gray-800 p-4 rounded-lg shadow-md hidden items-center justify-center flex-col lg:flex">
          <h3 className="text-xl font-semibold mb-2">{t.qrTitle}</h3>
          <QRCode value={currentUrl} size={200} />
        </div>

        <div className="bg-gray-800 p-4 rounded-lg shadow-md space-y-4 hidden lg:flex flex-col">
          <h3 className="text-xl font-semibold">{t.addVideo}</h3>
          <div>
            <input
              type="text"
              placeholder={t.videoUrl}
              value={newVideo.videoId}
              onChange={(e) => setNewVideo({ ...newVideo, videoId: e.target.value })}
              className="w-full p-2 rounded-md bg-gray-700 text-white"
            />
          </div>
          <button
            onClick={handleAddVideo}
            className={`w-full p-2 rounded-md ${addVideoLoading ? 'bg-gray-600' : 'bg-green-600'} text-white`}
          >
            {addVideoLoading ? t.adding : t.add}
          </button>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg shadow-md space-y-4">
          <h3 className="text-xl font-semibold">{t.randomUser}</h3>
          <button
            onClick={startCyclingUsers}
            className="w-full p-2 rounded-md bg-blue-600 text-white hover:bg-blue-500 transition"
            disabled={isCycling}
          >
            {t.randomUser}
          </button>
          {isCycling && (
            <div className="mt-4 p-4 bg-gray-700 rounded-md">
              <h4 className="text-lg font-semibold">{t.cycling}</h4>
              <p>{activeUser?.username || ''}</p>
            </div>
          )}
          {winner && !isCycling && (
            <div className="mt-4 p-4 bg-green-600 rounded-md">
              <h4 className="text-lg font-semibold">{t.selectedUser}:</h4>
              <p>{winner.username}</p>
              <p>ID: {winner.id}</p>
            </div>
          )}
        </div>
      </div>

      {/* Right Section */}
      <div className="lg:w-1/2 space-y-4 lg:mt-24">
        <div className="bg-gray-800 p-4 rounded-lg shadow-md" >
          <h3 className="text-xl font-semibold mb-2 flex items-center">
            <FaMusic className="animate-bounce text-green-400 text-2xl mt-2 mr-2" />
            {t.nowPlaying}
          </h3>
          {currentVideo ? (
            <div>
              <p className="font-bold">{currentVideo.title || t.unknown}</p>
              <p className="text-sm text-gray-400">
                {t.addedBy}: {currentVideo.addedBy?.username || t.unknown}
              </p>
            </div>
          ) : (
            <p className="text-gray-400">{t.noVideos}</p>
          )}
        </div>

        {/* Up Next */}
        <div className="bg-gray-800 p-4 rounded-lg shadow-md cursor-pointer" onClick={()=>jumpToVideo(playlist.currentVideoIndex+1)}>
          <h3 className="text-xl font-semibold mb-2 flex items-center">
            <FaArrowRight className="mr-2 text-blue-500" /> {t.upNext}
          </h3>
          {nextVideo ? (
            <div>
              <p className="font-bold">{nextVideo.title || t.unknown}</p>
              <p className="text-sm text-gray-400">
                {t.addedBy}: {nextVideo.addedBy?.username || t.unknown}
              </p>
            </div>
          ) : (
            <p className="text-gray-400">{t.noUpcoming}</p>
          )}
        </div>

        <div className="bg-gray-800 p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold">{t.following}</h3>
          <ul className="space-y-2">
            {remainingVideos.map((video: any, index: number) => {
              const globalIndex = playlist.currentVideoIndex + 2 + index; // Adjust for global index

              return (
                <li key={video.videoId} onClick={() => jumpToVideo(globalIndex)} className="cursor-pointer">
                  <div
                    className={`p-2 rounded-lg ${
                      globalIndex === playlist.currentVideoIndex ? 'bg-green-600' : 'bg-gray-700'
                    }`}
                  >
                    <p className="font-bold">{video.title}</p>
                    <p className="text-sm text-gray-400">
                      {t.addedBy}: {video.addedBy?.username || t.unknown}
                    </p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      {/* Add Video Modal */}
      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-xl font-semibold mb-4">{t.addVideo}</h3>
            <div className="space-y-2">
              <input
                type="text"
                placeholder={t.videoUrl}
                value={newVideo.videoId}
                onChange={(e) => setNewVideo({ ...newVideo, videoId: e.target.value })}
                className="w-full p-2 rounded-md bg-gray-700 text-white placeholder-gray-400"
              />
              <button
                onClick={handleAddVideo}
                className={`w-full p-2 rounded-md flex items-center justify-center ${
                  addVideoLoading ? 'bg-gray-600' : 'bg-green-600 hover:bg-green-500'
                } text-white transition`}
                disabled={addVideoLoading}
              >
                {addVideoLoading ? <FaSpinner className="animate-spin mr-2" /> : <FaPlus className="mr-2" />}
                {addVideoLoading ? t.adding : t.add}
              </button>
            </div>
            <button
              onClick={() => setModalOpen(false)}
              className="w-full mt-4 p-2 bg-red-600 text-white rounded-md hover:bg-red-500 transition"
            >
              {t.cancel}
            </button>
          </div>
        </div>
      )}

      {/* QR Modal */}
      {qrModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96 text-center">
            <h3 className="text-xl font-semibold mb-4">{t.qrTitle}</h3>
            <QRCode value={currentUrl} size={200} className="mx-auto" />
            <button
              onClick={() => setQrModalOpen(false)}
              className="w-full mt-4 p-2 bg-red-600 text-white rounded-md hover:bg-red-500 transition"
            >
              {t.close}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlaylistDetail;
