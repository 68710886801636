import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import { APIBase } from 'api/hosts';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  FaArrowLeft,
  FaArrowRight,
  FaClipboard,
  FaHeart,
  FaMusic,
  FaPlus,
  FaQrcode,
  FaSpinner,
  FaTrophy,
} from 'react-icons/fa';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const extractVideoId = (url: string): string | null => {
  try {
    const urlObj = new URL(url);

    if (urlObj.hostname.includes('youtube.com')) {
      return urlObj.searchParams.get('v'); // Extract 'v' parameter for standard YouTube URLs
    }

    if (urlObj.hostname.includes('youtu.be')) {
      return urlObj.pathname.substring(1).split('?')[0]; // Extract video ID from shortened URLs
    }

    return null; // Unsupported format
  } catch (error) {
    console.error('Invalid URL:', url);
    return null;
  }
};

const PlaylistDetail: React.FC = () => {
  const { playlistId } = useParams<{ playlistId: string }>();
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage(); // Language context
  const [playlist, setPlaylist] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [addVideoLoading, setAddVideoLoading] = useState<boolean>(false);
  const [newVideo, setNewVideo] = useState({ videoId: '' });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [qrModalOpen, setQrModalOpen] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>(null);
  // State to handle modal visibility
  const [modalOpenParticipants, setModalOpenParticipants] = useState<boolean>(false);

  const navigate = useNavigate();
  const [currentSongDetails, setCurrentSongDetails] = useState<{ title: string; addedBy: string | null; _id: string }>({
    title: '',
    addedBy: null,
    _id: '',
  });
  const lastDisplayedSongId = useRef<string | null>(null); // Move useRef to the top level of the component
  const translations = {
    english: {
      nowPlaying: 'Now Playing',
      addedBy: 'Added by',
      participants: 'Participants',
      participant: 'Participant',
      noParticipants: 'No participants yet.',
      unknown: 'Unknown',
      upNext: 'Up Next',
      noVideos: 'No videos in the playlist.',
      playlistNotFound: 'Playlist not found.',
      loading: 'Loading...',
      addVideo: 'Add New Video',
      videoTitle: 'Video Title',
      videoUrl: 'Video URL',
      adding: 'Adding...',
      add: 'Add Video',
      cancel: 'Cancel',
      qrTitle: 'Scan to Open Playlist',
      close: 'Close',
      success: 'Success',
      videoAdded: 'Video added successfully.',
      coinsEarned: 'You earned 2 coins for adding a new song!',
      fetchError: 'Could not fetch playlist details.',
      invalidUrl: 'Invalid YouTube URL.',
      fieldsRequired: 'Both video URL and title are required.',
      addVideoError: 'Could not add video to the playlist.',
      noUpcoming: 'No upcoming video.',
      remainingVideos: 'No remaining videos in the playlist.',
      error: 'Error',
      songInQueue: 'You already have a song waiting in the queue. Please wait for it to play before adding another.',
      currentVideo: 'Now Playing',
      requestedBy: 'Requested by',
      currentSongChanged: 'The current song has changed.',
      loadin_song_name: 'Loading song name...',
      loading_duration: '⏳ Fetching duration...',
      following: 'Following',
      contestDescription:
        'This playlist in this room is a contest one. People can vote by giving likes. The cutoff hour will be defined by the bar host, and the winner will automatically receive 3000 coins that can be used to claim shots or drinks.',
    },
    spanish: {
      nowPlaying: 'Reproduciendo Ahora',
      addedBy: 'Añadido por',
      participants: 'Participantes',
      participant: 'Participante',
      noParticipants: 'Aún no hay participantes.',
      unknown: 'Desconocido',
      upNext: 'A Continuación',
      noVideos: 'No hay videos en la lista de reproducción.',
      playlistNotFound: 'Lista de reproducción no encontrada.',
      loading: 'Cargando...',
      addVideo: 'Añadir Nuevo Video',
      videoTitle: 'Título del Video',
      videoUrl: 'URL del Video',
      adding: 'Añadiendo...',
      add: 'Añadir Video',
      cancel: 'Cancelar',
      qrTitle: 'Escanea para Abrir la Lista',
      close: 'Cerrar',
      success: 'Éxito',
      videoAdded: 'Video añadido con éxito.',
      coinsEarned: '¡Ganaste 2 monedas por añadir una nueva canción!',
      fetchError: 'No se pudo cargar la lista de reproducción.',
      invalidUrl: 'URL de YouTube inválida.',
      fieldsRequired: 'Se requieren tanto la URL del video como el título.',
      addVideoError: 'No se pudo añadir el video a la lista de reproducción.',
      noUpcoming: 'No hay videos siguientes.',
      remainingVideos: 'No hay videos restantes en la lista de reproducción.',
      error: 'Error',
      songInQueue:
        'Ya tienes una canción esperando en la cola. Por favor, espera a que se reproduzca antes de añadir otra.',
      currentVideo: 'Reproduciendo ahora',
      requestedBy: 'Solicitado por',
      currentSongChanged: 'La canción actual ha cambiado.',
      loadin_song_name: 'Cargando nombre de la canción...',
      loading_duration: '⏳ Obteniendo duración...',
      following: 'Siguientes',
      contestDescription:
        'Esta lista de reproducción en esta sala es un concurso. Las personas pueden votar dando likes. La hora de cierre será definida por el anfitrión del bar, y el ganador recibirá automáticamente 3000 monedas que pueden usarse para reclamar tragos o bebidas.',
    },
    polish: {
      nowPlaying: 'Teraz Odtwarzane',
      addedBy: 'Dodane przez',
      participants: 'Uczestnicy',
      participant: 'Uczestnik',
      noParticipants: 'Brak uczestników.',
      unknown: 'Nieznany',
      upNext: 'Dalej',
      noVideos: 'Brak wideo na liście odtwarzania.',
      playlistNotFound: 'Lista odtwarzania nie znaleziona.',
      loading: 'Ładowanie...',
      addVideo: 'Dodaj Nowe Wideo',
      videoTitle: 'Tytuł Wideo',
      videoUrl: 'URL Wideo',
      adding: 'Dodawanie...',
      add: 'Dodaj Wideo',
      cancel: 'Anuluj',
      qrTitle: 'Zeskanuj, aby otworzyć listę odtwarzania',
      close: 'Zamknij',
      success: 'Sukces',
      videoAdded: 'Wideo pomyślnie dodane.',
      coinsEarned: 'Zdobyłeś 2 monety za dodanie nowej piosenki!',
      fetchError: 'Nie udało się załadować listy odtwarzania.',
      invalidUrl: 'Nieprawidłowy URL YouTube.',
      fieldsRequired: 'Wymagany jest zarówno URL wideo, jak i tytuł.',
      addVideoError: 'Nie udało się dodać wideo do listy odtwarzania.',
      noUpcoming: 'Brak kolejnych wideo.',
      remainingVideos: 'Brak pozostałych wideo na liście odtwarzania.',
      error: 'Błąd',
      songInQueue: 'Masz już piosenkę w kolejce. Poczekaj, aż zostanie odtworzona, zanim dodasz kolejną.',
      currentVideo: 'Teraz odtwarzane',
      requestedBy: 'Poproszone przez',
      currentSongChanged: 'Obecna piosenka została zmieniona.',
      loadin_song_name: 'Ładowanie nazwy piosenki...',
      loading_duration: '⏳ Pobieranie długości...',
      following: 'Następne',
      contestDescription:
        'Ta lista odtwarzania w tym pokoju to konkurs. Ludzie mogą głosować, dając polubienia. Godzina zakończenia zostanie określona przez gospodarza baru, a zwycięzca automatycznie otrzyma 3000 monet, które można wymienić na drinki lub napoje.',
    },
  };

  const t = translations[language as keyof typeof translations];

  const handlePasteClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/)|youtu\.be\/)[\w\-]+/;

      if (youtubeRegex.test(text)) {
        setNewVideo((prev) => ({ ...prev, videoId: text }));
        Swal.fire({
          title: 'YouTube Link Pasted!',
          text: 'A YouTube link has been pasted into the field.',
          icon: 'success',
          background: '#000000',
          color: '#FFFFFF',
          timer: 2000,
          showConfirmButton: false,
        });
      } else {
        Swal.fire({
          title: 'No Valid Link',
          text: 'Clipboard does not contain a valid YouTube link.',
          icon: 'error',
          background: '#000000',
          color: '#FFFFFF',
          timer: 2000,
          showConfirmButton: false,
        });
      }
    } catch (error) {
      console.error('Clipboard access failed:', error);
      Swal.fire({
        title: 'Clipboard Access Denied',
        text: 'Please allow clipboard access in your browser settings.',
        icon: 'error',
        background: '#000000',
        color: '#FFFFFF',
        timer: 3000,
      });
    }
  };
  useEffect(() => {
    if (playlistId) {
      fetchPlaylistDetails();
      fetchUserProfile();
      const interval = setInterval(fetchPlaylistDetails, 10000); // Fetch every 10 seconds
      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [playlistId]);

  const fetchPlaylistDetails = async () => {
    try {
      const response = await fetch(`${APIBase}/client/playlist/${playlistId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        const currentVideo = data.playlist.videos[data.playlist.currentVideoIndex];

        console.log('currentVideo> ', currentVideo);
        console.log('currentSongDetails> ', currentSongDetails);

        // Compare currentVideo ID with the last displayed song ID
        if (currentVideo && currentVideo._id !== lastDisplayedSongId.current) {
          setCurrentSongDetails({
            title: currentVideo.title || t.unknown,
            addedBy: currentVideo.addedBy?.username || t.unknown,
            _id: currentVideo._id,
          });

          lastDisplayedSongId.current = currentVideo._id; // Update the ref to the new song ID

          // Display custom Swal for the current video
          Swal.fire({
            html: `
              <div class="flex flex-col items-center justify-center text-white">
                <h2 class="text-4xl font-extrabold text-center animate-bounce">${t.currentVideo}</h2>
                <h3 class="text-2xl font-semibold mt-4">${currentVideo.title || t.unknown}</h3>
                <p class="text-lg mt-2">${t.requestedBy}: <span class="font-medium">${
              currentVideo.addedBy?.username || t.unknown
            }</span></p>
              </div>
            `,
            background: 'rgb(31 41 55)', // Tailwind "bg-gray-800"
            color: '#FFFFFF',
            timer: 10000,
            showConfirmButton: false,
          });
        }

        setPlaylist(data.playlist);
      } else {
        throw new Error(t.fetchError);
      }
    } catch (error) {
      console.error('Error fetching playlist details:', error);
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        background: '#000000',
        color: '#FFFFFF',
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchUserProfile = async () => {
    try {
      const response = await fetch(`${APIBase}/client/user`, {
        method: 'GET',
        headers: { Authorization: `Bearer ${currentToken}`, 'Content-Type': 'application/json' },
      });

      if (response.ok) {
        const data = await response.json();
        setUserData(data);
      } else {
        throw new Error('Failed to fetch user profile');
      }
    } catch (error) {
      Swal.fire({ title: t.error, text: t.fetchError, icon: 'error' });
    }
  };

  const handleAddVideo = async () => {
    const { videoId: videoUrl } = newVideo;

    if (!videoUrl) {
      Swal.fire({
        title: t.error,
        text: t.fieldsRequired,
        icon: 'error',
      });
      return;
    }

    const videoId = extractVideoId(videoUrl);

    if (!videoId) {
      Swal.fire({
        title: t.error,
        text: t.invalidUrl,
        icon: 'error',
      });
      return;
    }

    // Check if the user already has a song in the queue
    const unplayedVideos = playlist?.videos.filter((_: any, index: number) => index >= playlist.currentVideoIndex + 1);
    const userHasSongInQueue = unplayedVideos?.some((video: any) => video.addedBy?._id === userData?._id);

    if (userHasSongInQueue) {
      Swal.fire({
        title: t.error,
        text: t.songInQueue,
        icon: 'error',
      });
      return;
    }

    setAddVideoLoading(true);
    try {
      const response = await fetch(`${APIBase}/client/playlist/${playlistId}/addVideo`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ videoId, playlistId }),
      });

      if (response.ok) {
        Swal.fire({
          title: t.success,
          text: t.videoAdded,
          icon: 'success',
        });
        // Show coins earned notification
        Swal.fire({
          title: t.success,
          text: t.coinsEarned,
          icon: 'info',
          timer: 2000,
          showConfirmButton: false,
        });

        fetchPlaylistDetails();
        setNewVideo({ videoId: '' });
        setModalOpen(false);
      } else {
        throw new Error('Failed to add video');
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.addVideoError,
        icon: 'error',
      });
    } finally {
      setAddVideoLoading(false);
    }
  };

  const isAdmin = userData?.role === 'admin';

  const sendLikeInteraction = async (recipientId: string) => {
    try {
      const response = await fetch(`${APIBase}/client/playlist/${playlistId}/interactions`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          recipient: recipientId,
        }),
      });

      if (response.ok) {
        Swal.fire({
          title: t.success,
          text: 'Like sent successfully!',
          icon: 'success',
          timer: 2000,
          showConfirmButton: false,
        });

        fetchPlaylistDetails();
      } else {
        const errorData = await response.json();
        Swal.fire({
          title: t.error,
          text: errorData.message || 'Failed to send like.',
          icon: 'error',
        });
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: 'Could not send like.',
        icon: 'error',
      });
    }
  };

  const freezeVotes = async () => {
    try {
      const response = await fetch(`${APIBase}/client/playlist/${playlistId}/freeze`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const { winner } = await response.json();
        Swal.fire({
          title: 'Votes Frozen!',
          text: `Winner: ${winner.user.username} with ${winner.likes} likes.`,
          icon: 'success',
          background: '#000000',
          color: '#FFFFFF',
        });
        fetchPlaylistDetails(); // Refresh the data
      } else {
        const { message } = await response.json();
        Swal.fire({
          title: 'Error',
          text: message,
          icon: 'error',
          background: '#000000',
          color: '#FFFFFF',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to freeze votes.',
        icon: 'error',
        background: '#000000',
        color: '#FFFFFF',
      });
    }
  };

  const currentVideo = playlist?.videos[playlist.currentVideoIndex] || null;
  const nextVideo = playlist?.videos[playlist.currentVideoIndex + 1] || null;
  const remainingVideos = playlist?.videos.slice(playlist.currentVideoIndex + 2) || [];
  const currentUrl = window.location.href;

  console.log("playlist> ", playlist);


  const updatedParticipants = playlist?.participants.map((participant: any) => {
    // Extract likes for the participant
    const likesForParticipant = playlist?.userInteractions.filter(
      (interaction: any) => interaction.type === 'like' && interaction.recipient === participant._id,
    );

    return {
      ...participant,
      hasLiked: playlist?.userInteractions.some(
        (interaction: any) =>
          interaction.type === 'like' &&
          interaction.initiator === userData?._id &&
          interaction.recipient === participant._id,
      ),
      likes: likesForParticipant || [], // Include all likes for the participant
      canVote: !playlist?.winner, // Disable voting if a winner exists
    };
  });

  console.log("updatedParticipants> ", updatedParticipants);

  if (loading) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <FaSpinner className="animate-spin text-4xl text-gray-400" />
        <p>{t.loading}</p>
      </div>
    );
  }

  if (!playlist) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <p>{t.playlistNotFound}</p>
      </div>
    );
  }

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 flex flex-col lg:flex-row lg:space-x-4 py-20 relative">
      {/* Left Section: Playlist and Controls */}
      <div className="lg:w-1/2 flex flex-col space-y-4">
        {/* Return Button */}
        <div className="relative w-full h-20">
          <button
            onClick={() => navigate(-1)}
            className="absolute top-4 bg-gray-700 text-white p-2 rounded-full hover:bg-gray-600 transition w-12 h-12 flex items-center justify-center"
          >
            <FaArrowLeft className="text-xl" />
          </button>
          {/* QR Button */}
          <button
            onClick={() => setQrModalOpen(true)}
            className={`absolute top-4 ${
              playlist?.type === 'contest' ? 'right-36' : 'right-20'
            } bg-blue-600 text-white p-2 rounded-full hover:bg-blue-500 transition w-12 h-12 flex items-center justify-center lg:hidden`}
          >
            <FaQrcode className="text-xl" />
          </button>
          {/* Trophy Button */}
          {playlist?.type === 'contest' && (
            <button
              onClick={() => setModalOpenParticipants(true)}
              className="absolute top-4 right-20 bg-yellow-500 text-white p-2 rounded-full hover:bg-yellow-400 transition w-12 h-12 flex items-center justify-center"
              title="View Leaderboard"
            >
              <FaTrophy className="text-xl" />
            </button>
          )}
          <button
            onClick={() => setModalOpen(true)}
            className="absolute top-4 right-4 bg-green-600 text-white p-2 rounded-full flex items-center justify-center hover:bg-green-500 transition w-12 h-12 flex-shrink-0  lg:hidden"
          >
            <div className="relative">
              <FaMusic className="text-xl" />
              <FaPlus className="absolute text-xs text-white top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-green-600 rounded-full" />
            </div>
          </button>
        </div>
        <h2 className="text-2xl font-bold tracking-tight text-left mb-4">{playlist.name}</h2>

        {/* QR Code */}
        <div className="bg-gray-800 p-4 rounded-lg shadow-md flex-col items-center space-y-2 hidden lg:flex">
          <h3 className="text-xl font-semibold mb-2">{t.qrTitle}</h3>
          <QRCode value={currentUrl} size={200} />
        </div>

        {/* Add New Video Form */}
        <div className="bg-gray-800 p-4 rounded-lg shadow-md space-y-4 hidden lg:flex items-start justify-start flex-col">
          <h3 className="text-xl font-semibold">{t.addVideo}</h3>
          <div className="w-full  flex flex-col space-y-2">
            <div className="relative w-full">
              <input
                type="text"
                placeholder="Video URL"
                value={newVideo.videoId}
                onChange={(e) => setNewVideo({ ...newVideo, videoId: e.target.value })}
                className="w-full p-2 rounded-md bg-gray-700 text-white placeholder-gray-400 pr-12"
              />
              <button
                onClick={handlePasteClipboard}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white focus:outline-none"
                title="Paste YouTube Link"
              >
                <FaClipboard size={20} />
              </button>
            </div>

            <button
              onClick={handleAddVideo}
              className={`w-full p-2 rounded-md flex items-center justify-center ${
                addVideoLoading ? 'bg-gray-600' : 'bg-green-600 hover:bg-green-500'
              } text-white transition`}
              disabled={addVideoLoading}
            >
              {addVideoLoading ? <FaSpinner className="animate-spin mr-2" /> : <FaPlus className="mr-2" />}
              {addVideoLoading ? t.adding : t.add}
            </button>
          </div>
        </div>
      </div>

      {/* Right Section: Playlist Details */}
      <div className="lg:w-1/2 flex flex-col space-y-4 lg:pt-36">
        {/* Playlist Name */}

        {/* Now Playing */}
        <div className="bg-gray-800 p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-2 flex items-center">
            <FaMusic className="animate-bounce text-green-400 text-2xl mt-2 mr-2" />
            {t.nowPlaying}
          </h3>
          {currentVideo ? (
            <div>
              <p className="font-bold">1. {currentVideo.title || t.loadin_song_name}</p>
              <p className="text-sm text-gray-400">
                {currentVideo.duration
                  ? `${Math.floor(currentVideo.duration / 60)}:${String(currentVideo.duration % 60).padStart(2, '0')}`
                  : t.loading_duration}
              </p>
              <p className="text-sm text-gray-400">
                {t.addedBy}: {currentVideo.addedBy?.username || t.unknown}
              </p>
            </div>
          ) : (
            <p className="text-gray-400">{t.noVideos}</p>
          )}
        </div>

        {/* Up Next */}
        <div className="bg-gray-800 p-4 rounded-lg shadow-md">
          <h3 className="text-xl font-semibold mb-2 flex items-center">
            <FaArrowRight className="mr-2 text-blue-500" /> {t.upNext}
          </h3>
          {nextVideo ? (
            <div>
              <p className="font-bold">2. {nextVideo.title || t.loadin_song_name}</p>
              <p className="text-sm text-gray-400">
                {currentVideo.duration
                  ? `${Math.floor(currentVideo.duration / 60)}:${String(currentVideo.duration % 60).padStart(2, '0')}`
                  : t.loading_duration}
              </p>
              <p className="text-sm text-gray-400">
                {t.addedBy}: {nextVideo.addedBy?.username || t.unknown}
              </p>
            </div>
          ) : (
            <p className="text-gray-400">{t.noUpcoming}</p>
          )}
        </div>

        {/* Video List */}
        <div className="bg-gray-800 p-4 rounded-lg shadow-md flex-1 overflow-auto">
          <h3 className="text-xl font-semibold mb-2">{t.following}</h3>
          {remainingVideos.length > 0 ? (
            <ul className="space-y-2">
              {remainingVideos.map((video: any, index: number) => {
                return (
                  <li key={video.videoId} className="p-2 rounded-lg bg-gray-700">
                    <div>
                      <p className="font-bold text-sm">
                        {`${index + 3}. ${video.title || t.loadin_song_name}`} {/* Number order and title */}
                      </p>
                      <p className="text-sm text-gray-400">
                        {currentVideo.duration
                          ? `${Math.floor(currentVideo.duration / 60)}:${String(currentVideo.duration % 60).padStart(
                              2,
                              '0',
                            )}`
                          : t.loading_duration}
                      </p>
                      <p className="text-xs text-gray-400">
                        {t.addedBy}: {video.addedBy?.username || t.unknown}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p className="text-gray-400">{t.remainingVideos}</p>
          )}
        </div>

        {modalOpenParticipants && (
          <div className="fixed inset-0 bg-gradient-to-bl from-gray-900 via-gray-800 to-black bg-opacity-90 flex items-center justify-center z-50">
            <div className="bg-gray-900/70 rounded-xl shadow-2xl max-w-md w-90vw max-h-[70vh] overflow-auto relative backdrop-blur-md">
              {/* Header */}
              <div className="flex justify-between items-center p-6 border-b border-gray-700">
                <h3 className="text-lg font-bold text-gray-200 uppercase tracking-wide">Leaderboard</h3>
                <button
                  onClick={() => setModalOpenParticipants(false)}
                  className="w-8 h-8 flex items-center justify-center rounded-full text-gray-300 hover:bg-gray-600 transition"
                >
                  ✕
                </button>
              </div>
              {/* Contest Description */}
              <div className="mt-6 p-4 bg-gray-700 rounded-lg shadow-md text-white text-sm">{t.contestDescription}</div>

              <div className="p-6 space-y-4">
                {isAdmin && !playlist?.winner && (
                  <div className="text-center mt-6">
                    <button
                      className="flex items-center justify-center px-8 py-3 bg-gradient-to-r from-red-600 via-purple-600 to-blue-600 text-white font-semibold rounded-xl shadow-md hover:from-red-500 hover:via-purple-500 hover:to-blue-500 transition-transform transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50"
                      onClick={freezeVotes}
                    >
                      <FaTrophy className="mr-3 text-yellow-300 text-2xl animate-pulse" />
                      <span className="text-lg">Freeze Votes and Select Winner</span>
                    </button>
                  </div>
                )}

                {/* Sort participants by likes */}
                {updatedParticipants
                  ?.sort((a: any, b: any) => (b.likes?.length || 0) - (a.likes?.length || 0))
                  .map((participant: any, index: number) => {
                    const isTopThree = index < 3;

                    return (
                      <div
                        key={participant._id}
                        className={`flex items-center space-x-4 p-4 rounded-lg shadow-lg transition-all duration-300 ${
                          isTopThree
                            ? index === 0
                              ? 'bg-gradient-to-r from-purple-400 via-blue-400 to-teal-300 text-gray-900'
                              : index === 1
                              ? 'bg-gradient-to-r from-blue-300 via-teal-200 to-gray-300 text-gray-900'
                              : 'bg-gradient-to-r from-teal-200 via-gray-300 to-gold-300 text-gray-900'
                            : 'bg-gray-800/60 text-gray-300'
                        } hover:scale-105`}
                        style={isTopThree ? { boxShadow: '0 0 15px rgba(255, 255, 255, 0.2)' } : {}}
                      >
                        <div
                          className={`w-12 h-12 shrink-0 flex items-center justify-center rounded-full text-xl font-bold ${
                            isTopThree ? 'bg-white text-gray-900 shadow-lg' : 'bg-gray-700 text-gray-300'
                          }`}
                        >
                          {index + 1}
                        </div>
                        <div className="text-sm font-medium truncate w-full">{participant.username || t.unknown}</div>
                        <div
                          className={`relative flex items-center justify-center ml-auto ${
                            participant.canVote ? '' : 'cursor-not-allowed opacity-50'
                          }`}
                          onClick={() => participant.canVote && sendLikeInteraction(participant._id)}
                          title={participant.canVote ? 'Like' : 'Voting disabled (winner selected)'}
                        >
                          <FaHeart
                            className={`text-2xl w-10 h-10 ${participant.hasLiked ? 'text-pink-400' : 'text-gray-400'}`}
                          />
                          <span className="absolute inset-0 flex items-center justify-center text-xs font-semibold">
                            {participant.likes?.length || 0}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* Add Video Modal */}
      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-xl font-semibold mb-4">{t.addVideo}</h3>
            <div className="space-y-2">
              <div className="relative w-full">
                <input
                  type="text"
                  placeholder="Video URL"
                  value={newVideo.videoId}
                  onChange={(e) => setNewVideo({ ...newVideo, videoId: e.target.value })}
                  className="w-full p-2 rounded-md bg-gray-700 text-white placeholder-gray-400 pr-12"
                />
                <button
                  onClick={handlePasteClipboard}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white focus:outline-none"
                  title="Paste YouTube Link"
                >
                  <FaClipboard size={20} />
                </button>
              </div>
              <button
                onClick={handleAddVideo}
                className={`w-full p-2 rounded-md flex items-center justify-center ${
                  addVideoLoading ? 'bg-gray-600' : 'bg-green-600 hover:bg-green-500'
                } text-white transition`}
                disabled={addVideoLoading}
              >
                {addVideoLoading ? <FaSpinner className="animate-spin mr-2" /> : <FaPlus className="mr-2" />}
                {addVideoLoading ? t.adding : t.add}
              </button>
            </div>
            <button
              onClick={() => setModalOpen(false)}
              className="w-full mt-4 p-2 bg-red-600 text-white rounded-md hover:bg-red-500 transition"
            >
              {t.cancel}
            </button>
          </div>
        </div>
      )}

      {/* QR Modal */}
      {qrModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96 text-center">
            <h3 className="text-xl font-semibold mb-4">{t.qrTitle}</h3>
            <QRCode value={currentUrl} size={200} className="mx-auto" />
            <button
              onClick={() => setQrModalOpen(false)}
              className="w-full mt-4 p-2 bg-red-600 text-white rounded-md hover:bg-red-500 transition"
            >
              {t.close}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlaylistDetail;
