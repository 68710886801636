import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaChevronLeft, FaCoins, FaQrcode } from 'react-icons/fa';
import Modal from 'react-modal';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const translations = {
  english: {
    menuItems: 'Menu Items with Discounts',
    discounts: 'Discounts',
    pointsRequired: 'Points Required',
    priceWithDiscount: 'Price with Discount',
    back: 'Back to Menu',
    error: 'Error',
    fetchError: 'Failed to load menu items. Please try again later.',
    close: 'Close',
    qrCodeDetails: 'QR Code Details',
    name: 'Name',
    description: 'Description',
    price: 'Price',
    notEnoughPoints: 'Not Enough Points',
    earnPoints: 'Earn Coins',
  },
  spanish: {
    menuItems: 'Elementos del Menú con Descuentos',
    discounts: 'Descuentos',
    pointsRequired: 'Puntos Requeridos',
    priceWithDiscount: 'Precio con Descuento',
    back: 'Volver al Menú',
    error: 'Error',
    fetchError: 'No se pudieron cargar los elementos del menú. Inténtalo de nuevo más tarde.',
    close: 'Cerrar',
    qrCodeDetails: 'Detalles del Código QR',
    name: 'Nombre',
    description: 'Descripción',
    price: 'Precio',
    notEnoughPoints: 'No tienes suficientes puntos',
    earnPoints: 'Gana Monedas',
  },
  polish: {
    menuItems: 'Pozycje Menu z Rabatami',
    discounts: 'Zniżki',
    pointsRequired: 'Wymagane Monety',
    priceWithDiscount: 'Cena ze Zniżką',
    back: 'Powrót do Menu',
    error: 'Błąd',
    fetchError: 'Nie udało się załadować pozycji menu. Spróbuj ponownie później.',
    close: 'Zamknij',
    qrCodeDetails: 'Szczegóły Kodu QR',
    name: 'Nazwa',
    description: 'Opis',
    price: 'Cena',
    notEnoughPoints: 'Brak wystarczającej liczby punktów',
    earnPoints: 'Zdobywaj Monety',
  },
};

const MenuItemsWithDiscounts: React.FC = () => {
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const { barId } = useParams<{ barId: string }>();
  const navigate = useNavigate();
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const t = translations[language as keyof typeof translations];
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    fetchMenuItems();
    fetchUserData();
  }, [barId, currentToken]);

  const fetchUserData = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    const endpoint = 'client/user';
    await fetch(`${APIBase}/${endpoint}`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        setUserData(responseJson);
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const fetchMenuItems = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/menu-item?venue=${barId}`, data);
      if (response.ok) {
        const result = await response.json();
        const discountedItems = result.filter((item: any) => item.discounts?.length > 0);
        setMenuItems(discountedItems);
      } else {
        throw new Error(t.fetchError);
      }
    } catch {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const openQrModal = (item: any) => {
    setSelectedItem(item);
    setQrModalOpen(true);
  };

  const closeQrModal = () => {
    setQrModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <div className="text-white min-h-screen bg-gradient-to-b from-black via-gray-900 to-black p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="fixed top-20 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out flex items-center"
      >
        <FaChevronLeft className="text-xl" />
      </button>
      <div className="max-w-3xl mx-auto mt-16">
        <h2 className="text-3xl font-bold mb-6 text-center">{t.menuItems}</h2>
        {menuItems.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {menuItems.map((menuItem) => (
              <div
                key={menuItem._id}
                className={`p-4 rounded-lg shadow-lg flex flex-col cursor-pointer transition-all duration-300 ease-in-out ${
                  userData?.totalPoints >= menuItem.discounts[0]?.pointsRequired
                    ? 'bg-green-800 hover:bg-green-700'
                    : 'bg-gray-800 hover:bg-gray-700'
                }`}
                onClick={() => {
                  if (userData?.totalPoints >= menuItem.discounts[0]?.pointsRequired) {
                    openQrModal(menuItem);
                  }
                }}
              >
                <div className="flex items-center">
                  {menuItem.imageURL && (
                    <img
                      src={menuItem.imageURL}
                      alt={menuItem.name}
                      className="w-16 h-16 object-cover rounded-md mr-4"
                    />
                  )}
                  <div>
                    <h4 className="text-lg font-semibold">{menuItem.name}</h4>
                    <p className="text-sm text-gray-400">{menuItem.description}</p>
                    <p className="text-sm text-gray-500">
                      {menuItem.price} {menuItem.currency}
                    </p>
                  </div>
                </div>
                {menuItem.discounts?.length > 0 && (
                  <div className="mt-4">
                    <h5 className="text-sm font-semibold  ">{t.discounts}:</h5>
                    <div className="flex flex-wrap gap-2 mt-2">
                      {menuItem.discounts.map((discount: any, index: number) => (
                        <div
                          key={index}
                          className={`p-3 rounded-lg shadow-md flex items-center justify-between w-full sm:w-auto ${
                            userData?.totalPoints >= discount.pointsRequired
                              ? 'border border-green-400'
                              : 'border border-red-400'
                          }`}
                        >
                          <FaQrcode
                            className={`${
                              userData?.totalPoints >= discount.pointsRequired ? 'text-green-400' : 'text-red-400'
                            } text-xl mr-2`}
                          />
                          <div>
                            <p className="text-white text-sm font-semibold">
                              {discount.priceWithDiscount} {menuItem.currency}
                            </p>
                            <p className="text-sm text-gray-300">
                              -{discount.pointsRequired} <FaCoins className="ml-1" />
                            </p>
                          </div>
                        </div>
                      ))}
                    </div>
                    {userData?.totalPoints < menuItem.discounts[0]?.pointsRequired && (
                      <button
                        className="mt-4 bg-red-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-red-500 w-full"
                        onClick={() => navigate('/app/partyticket/earn-coins/66cc50ad5d4ada8c64b24616')}
                      >
                        {t.earnPoints}
                      </button>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-400 mt-16">{t.fetchError}</p>
        )}
      </div>

      {/* QR Code Modal */}
      {selectedItem && (
        <Modal
          isOpen={qrModalOpen}
          onRequestClose={closeQrModal}
          className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-75"
        >
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-sm w-full text-white">
            <h2 className="text-2xl font-bold mb-4">{t.qrCodeDetails}</h2>
            <p>
              <strong>{t.name}:</strong> {selectedItem.name}
            </p>
            <p>
              <strong>{t.description}:</strong> {selectedItem.description}
            </p>
            <p>
              <strong>{t.price}:</strong> {selectedItem.price} {selectedItem.currency}
            </p>
            <p>
              <strong>{t.priceWithDiscount}:</strong> {selectedItem.discounts[0].priceWithDiscount || '0'}{' '}
              {selectedItem.currency}
            </p>
            <p>
              <strong>{t.pointsRequired}:</strong> {selectedItem.discounts[0]?.pointsRequired || 'N/A'}
            </p>
            <div className="flex justify-center bg-white p-5 mt-4 rounded-lg">
              <QRCode
                value={JSON.stringify({
                  menuItemId: selectedItem._id,
                  discountId: selectedItem.discount?._id,
                  pointsUsed: selectedItem.discount?.pointsRequired || 0,
                  venueId: barId,
                  priceWithDiscount: selectedItem.discount?.priceWithDiscount,
                  userId: userData._id,
                })}
                size={200}
              />
            </div>
            <button
              onClick={closeQrModal}
              className="mt-4 bg-blue-600 text-white py-2 px-4 rounded-lg shadow-md hover:bg-blue-500 w-full"
            >
              {t.close}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MenuItemsWithDiscounts;
