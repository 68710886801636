import { APIBase } from 'api/hosts';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ClapSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';

const translations = {
  english: {
    title: 'Password Recovery',
    emailPlaceholder: 'Email Address',
    recoverButton: 'Recover Password',
    login: 'Login',
    register: 'Register',
    recovering: 'Password Recovery...',
    requestSent: 'Request Sent',
    instructions: 'Instructions will be sent to your email',
    systemError: 'System Error',
    tryAgain: 'Please try again later',
    networkError: 'Network Error',
    connectionIssue: 'Please check your connection and try again',
  },
  spanish: {
    title: 'Recuperación de Contraseña',
    emailPlaceholder: 'Correo Electrónico',
    recoverButton: 'Recuperar Contraseña',
    login: 'Iniciar Sesión',
    register: 'Registrarse',
    recovering: 'Recuperando Contraseña...',
    requestSent: 'Solicitud Enviada',
    instructions: 'Se enviarán instrucciones a tu correo electrónico',
    systemError: 'Error del Sistema',
    tryAgain: 'Por favor, inténtalo más tarde',
    networkError: 'Error de Red',
    connectionIssue: 'Por favor, verifica tu conexión e inténtalo de nuevo',
  },
  polish: {
    title: 'Odzyskiwanie Hasła',
    emailPlaceholder: 'Adres Email',
    recoverButton: 'Odzyskaj Hasło',
    login: 'Zaloguj się',
    register: 'Rejestracja',
    recovering: 'Odzyskiwanie hasła...',
    requestSent: 'Żądanie Wysłane',
    instructions: 'Instrukcje zostaną wysłane na Twój email',
    systemError: 'Błąd Systemu',
    tryAgain: 'Spróbuj ponownie później',
    networkError: 'Błąd Sieci',
    connectionIssue: 'Sprawdź swoje połączenie i spróbuj ponownie',
  },
};

const RecoverPassword: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { language } = useLanguage(); // Language context
  const t = translations[language as keyof typeof translations];

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    Swal.fire({
      title: t.recovering,
      showConfirmButton: false,
      background: '#000000',
      color: '#FFFFFF',
    });

    const body = { email };
    const data: RequestInit = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(`${APIBase}/auth/recover`, data);
      if (response.ok) {
        navigate('/auth/login');
        Swal.fire({
          title: t.requestSent,
          text: t.instructions,
          showConfirmButton: false,
          timer: 3000,
          background: '#000000',
          color: '#FFFFFF',
        });
      } else {
        Swal.fire({
          title: t.systemError,
          text: t.tryAgain,
          showConfirmButton: false,
          timer: 3000,
          background: '#000000',
          color: '#FFFFFF',
        });
      }
    } catch {
      Swal.fire({
        title: t.networkError,
        text: t.connectionIssue,
        showConfirmButton: false,
        timer: 3000,
        background: '#000000',
        color: '#FFFFFF',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black p-6">
      <div className="w-full max-w-sm bg-black bg-opacity-70 rounded-lg shadow-md p-6">
        <h1 className="text-4xl text-white font-bold mb-6">{t.title}</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            placeholder={t.emailPlaceholder}
            value={email}
            onChange={handleChangeEmail}
            className="w-full px-3 py-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none"
            required
          />
          <button
            type="submit"
            className="w-full py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 flex items-center justify-center"
          >
            {loading ? <ClapSpinner size={15} color="#FFFFFF" /> : t.recoverButton}
          </button>
        </form>
        <div className="flex justify-between mt-4">
          <Link to="/auth/login" className="text-sm text-gray-400 hover:text-white">
            {t.login}
          </Link>
          <Link to="/auth/register" className="text-sm text-gray-400 hover:text-white">
            {t.register}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RecoverPassword;
