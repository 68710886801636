import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaChevronLeft, FaTrashAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';

interface Discount {
  pointsRequired: number;
  priceWithDiscount: number; // Changed from priceWithDiscount
}

interface MenuItemData {
  name: string;
  description: string;
  price: number;
  currency: string;
  availability: boolean;
  venue: string;
  imageURL: string;
  discounts: Discount[]; // Define the correct type for discounts
}

const translations = {
  english: {
    editMenuItem: 'Edit Menu Item',
    name: 'Name',
    description: 'Description',
    price: 'Price',
    currency: 'Currency',
    availability: 'Availability',
    venue: 'Venue',
    uploadImage: 'Upload Image',
    submit: 'Submit',
    success: 'Success',
    menuItemUpdated: 'Menu item updated successfully!',
    error: 'Error',
    submitError: 'Failed to update menu item. Please try again later.',
    back: 'Back',
    discounts: 'Discounts',
    pointsRequired: 'Points Required',
    priceWithDiscount: 'Price with Discount', // Updated to reflect new field
    addDiscount: 'Add Discount',
    removeDiscount: 'Remove Discount',
  },
  spanish: {
    editMenuItem: 'Editar Artículo del Menú',
    name: 'Nombre',
    description: 'Descripción',
    price: 'Precio',
    currency: 'Moneda',
    availability: 'Disponibilidad',
    venue: 'Lugar',
    uploadImage: 'Subir Imagen',
    submit: 'Enviar',
    success: 'Éxito',
    menuItemUpdated: '¡Artículo del menú actualizado con éxito!',
    error: 'Error',
    submitError: 'No se pudo actualizar el artículo del menú. Inténtalo de nuevo más tarde.',
    back: 'Regresar',
    discounts: 'Descuentos',
    pointsRequired: 'Puntos Requeridos',
    priceWithDiscount: 'Precio con Descuento', // Updated to reflect new field
    addDiscount: 'Agregar Descuento',
    removeDiscount: 'Eliminar Descuento',
  },
  polish: {
    editMenuItem: 'Edytuj Pozycję Menu',
    name: 'Nazwa',
    description: 'Opis',
    price: 'Cena',
    currency: 'Waluta',
    availability: 'Dostępność',
    venue: 'Miejsce',
    uploadImage: 'Prześlij Obraz',
    submit: 'Zatwierdź',
    success: 'Sukces',
    menuItemUpdated: 'Pozycja menu została pomyślnie zaktualizowana!',
    error: 'Błąd',
    submitError: 'Nie udało się zaktualizować pozycji w menu. Spróbuj ponownie później.',
    back: 'Powrót',
    discounts: 'Zniżki',
    pointsRequired: 'Wymagane Punkty',
    priceWithDiscount: 'Cena ze Zniżką', // Updated to reflect new field
    addDiscount: 'Dodaj Zniżkę',
    removeDiscount: 'Usuń Zniżkę',
  },
};

const currencyOptions = [
  { value: 'EUR', label: 'EUR - Euro' },
  { value: 'GBP', label: 'GBP - British Pound Sterling' },
  { value: 'CHF', label: 'CHF - Swiss Franc' },
  { value: 'PLN', label: 'PLN - Polish Zloty' },
  { value: 'SEK', label: 'SEK - Swedish Krona' },
  { value: 'NOK', label: 'NOK - Norwegian Krone' },
  { value: 'DKK', label: 'DKK - Danish Krone' },
  { value: 'CZK', label: 'CZK - Czech Koruna' },
  { value: 'HUF', label: 'HUF - Hungarian Forint' },
  { value: 'RON', label: 'RON - Romanian Leu' },
  { value: 'BGN', label: 'BGN - Bulgarian Lev' },
  { value: 'HRK', label: 'HRK - Croatian Kuna' },
  { value: 'RSD', label: 'RSD - Serbian Dinar' },
  { value: 'ISK', label: 'ISK - Icelandic Krona' },
  { value: 'MKD', label: 'MKD - Macedonian Denar' },
  { value: 'BAM', label: 'BAM - Bosnia-Herzegovina Convertible Mark' },
  { value: 'GEL', label: 'GEL - Georgian Lari' },
  { value: 'RUB', label: 'RUB - Russian Ruble' },
  { value: 'TRY', label: 'TRY - Turkish Lira' },
  { value: 'UAH', label: 'UAH - Ukrainian Hryvnia' },
  { value: 'BYN', label: 'BYN - Belarusian Ruble' },
  { value: 'MDL', label: 'MDL - Moldovan Leu' },
  { value: 'ALL', label: 'ALL - Albanian Lek' },
  { value: 'KZT', label: 'KZT - Kazakhstani Tenge' },
  { value: 'AZN', label: 'AZN - Azerbaijani Manat' },
  { value: 'AMD', label: 'AMD - Armenian Dram' },
  { value: 'GIP', label: 'GIP - Gibraltar Pound' },
  { value: 'NAD', label: 'NAD - Namibian Dollar' },
  { value: 'MNT', label: 'MNT - Mongolian Tugrik' },
  { value: 'RSD', label: 'RSD - Serbian Dinar' },
  { value: 'KGS', label: 'KGS - Kyrgyzstani Som' },
  { value: 'UZS', label: 'UZS - Uzbekistani Som' },
];

const availabilityOptions = [
  { value: true, label: 'Available' },
  { value: false, label: 'Not Available' },
];

const EditMenuItem: React.FC = () => {
  const { menuItemId } = useParams<{ menuItemId: string }>();
  const [menuItemData, setMenuItemData] = useState<MenuItemData>({
    name: '',
    description: '',
    price: 0,
    currency: '',
    availability: true,
    venue: '',
    imageURL: '',
    discounts: [], // Initialize discounts as an empty array
  });
  const [venues, setVenues] = useState([]);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    if (menuItemId) {
      fetchMenuItem(menuItemId);
    }
    fetchVenues();
  }, [currentToken, menuItemId]);

  const fetchVenues = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/venue`, data)
      .then((response) => response.json())
      .then((responseJson) => setVenues(responseJson))
      .catch(() => Swal.fire({ title: t.error, text: t.submitError, icon: 'error', timer: 3000 }));
  };

  const fetchMenuItem = async (menuItemId: string) => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/menu-item/${menuItemId}`, data)
      .then((response) => response.json())
      .then((responseJson) =>
        setMenuItemData({ ...responseJson, venue: responseJson.venue._id, discounts: responseJson.discounts || [] }),
      ) // Ensure discounts is set to an array if undefined
      .catch(() => Swal.fire({ title: t.error, text: t.submitError, icon: 'error', timer: 3000 }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setMenuItemData({ ...menuItemData, [name]: value });
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setMenuItemData({ ...menuItemData, [name]: value });
  };

  const handleCurrencyChange = (selectedOption: any) => {
    setMenuItemData({ ...menuItemData, currency: selectedOption.value });
  };

  const handleAvailabilityChange = (selectedOption: any) => {
    setMenuItemData({ ...menuItemData, availability: selectedOption.value });
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const uploadImage = async (): Promise<string | null> => {
    if (!imageFile) return null;

    const formData = new FormData();
    formData.append('mediaUpload', imageFile);

    const data: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
      body: formData,
    };

    try {
      const response = await fetch(`${APIBase}/client/menu-item/upload-media`, data);
      if (response.ok) {
        const responseJson = await response.json();
        return responseJson.imageURL;
      } else {
        throw new Error('Image upload failed');
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.submitError,
        icon: 'error',
        timer: 3000,
      });
      return null;
    }
  };

  const addDiscount = () => {
    const newDiscount = { pointsRequired: 0, priceWithDiscount: 0 };
    setMenuItemData({
      ...menuItemData,
      discounts: menuItemData?.discounts?.length ? [...menuItemData.discounts, newDiscount] : [newDiscount],
    });
  };

  const removeDiscount = (index: number) => {
    const updatedDiscounts = menuItemData.discounts.filter((_, i) => i !== index);
    setMenuItemData({ ...menuItemData, discounts: updatedDiscounts });
  };

  const handleDiscountChange = (index: number, field: string, value: number) => {
    const updatedDiscounts = menuItemData.discounts.map((discount, i) =>
      i === index ? { ...discount, [field]: value } : discount,
    );
    setMenuItemData({ ...menuItemData, discounts: updatedDiscounts });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (imageFile) {
      const imageURL = await uploadImage();
      if (imageURL) {
        menuItemData.imageURL = imageURL;
      }
    }

    const data: RequestInit = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(menuItemData),
    };

    await fetch(`${APIBase}/client/menu-item/${menuItemId}`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({ title: t.success, text: t.menuItemUpdated, icon: 'success', timer: 2000 });
          navigate('/app/admin/menu-items');
        } else {
          Swal.fire({ title: t.error, text: t.submitError, icon: 'error', timer: 3000 });
        }
      })
      .catch(() => Swal.fire({ title: t.error, text: t.submitError, icon: 'error', timer: 3000 }));
  };

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <h2 className="text-2xl mb-4">{t.editMenuItem}</h2>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="name">
            {t.name}
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={menuItemData.name}
            onChange={handleInputChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="description">
            {t.description}
          </label>
          <textarea
            id="description"
            name="description"
            value={menuItemData.description}
            onChange={handleTextareaChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="price">
            {t.price}
          </label>
          <input
            type="number"
            id="price"
            name="price"
            value={menuItemData.price}
            onChange={handleInputChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="currency">
            {t.currency}
          </label>
          <Select
            id="currency"
            name="currency"
            value={currencyOptions.find((option) => option.value === menuItemData.currency)}
            onChange={handleCurrencyChange}
            options={currencyOptions}
            className="w-full text-black"
            placeholder={t.currency}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="availability">
            {t.availability}
          </label>
          <Select
            id="availability"
            name="availability"
            value={availabilityOptions.find((option) => option.value === menuItemData.availability)}
            onChange={handleAvailabilityChange}
            options={availabilityOptions}
            className="w-full text-black"
            placeholder={t.availability}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="venue">
            {t.venue}
          </label>
          <select
            id="venue"
            name="venue"
            value={menuItemData.venue}
            onChange={handleInputChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          >
            <option value="">{t.venue}</option>
            {venues.map((venue: any) => (
              <option key={venue._id} value={venue._id}>
                {venue.name}
              </option>
            ))}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="imageUpload">
            {t.uploadImage}
          </label>
          <input
            type="file"
            id="imageUpload"
            name="imageUpload"
            onChange={handleImageUpload}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>

        {/* Discounts Section */}
        <div className="mb-4">
          <h3 className="text-lg mb-2">{t.discounts}</h3>
          {menuItemData.discounts.map((discount, index) => (
            <div key={index} className="flex items-center mb-2">
              <input
                type="number"
                placeholder={t.pointsRequired}
                value={discount.pointsRequired}
                onChange={(e) => handleDiscountChange(index, 'pointsRequired', parseInt(e.target.value))}
                className="px-2 py-1 w-1/2 bg-gray-800 text-white rounded mr-2"
              />
              <input
                type="number"
                placeholder="Price with Discount" // Change label
                value={discount.priceWithDiscount} // Changed from priceWithDiscount
                onChange={(e) => handleDiscountChange(index, 'priceWithDiscount', parseInt(e.target.value))}
                className="px-2 py-1 w-1/2 bg-gray-800 text-white rounded mr-2"
              />
              <button type="button" className="text-red-500" onClick={() => removeDiscount(index)}>
                <FaTrashAlt />
              </button>
            </div>
          ))}
          <button type="button" className="px-2 py-1 bg-green-600 text-white rounded" onClick={addDiscount}>
            {t.addDiscount}
          </button>
        </div>

        <div className="flex justify-end">
          <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            {t.submit}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditMenuItem;
