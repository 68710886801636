import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import { useLocationContext } from 'Contexts/LocationContext';
import { useContext, useEffect, useState } from 'react';
import { FaMapMarkerAlt, FaUserCircle, FaCoins } from 'react-icons/fa';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import CitySelectionModal from '../../PrivateApp/CitySelectionModal';

Modal.setAppElement('#root');

const translations = {
  english: {
    selectCity: 'Select City',
    gps: 'GPS',
    points: 'Points',
    pointsInfo: 'Use your points to get discounts on amazing deals! Click below to explore discounts.',
    viewDiscounts: 'View Discounts',
    earnPoints: 'How to Earn Points',
    earnPointsInfo: [
      'Participate in activities',
      'Follow us on social media',
      'Sing a song at karaoke',
      'Leave us a review',
    ],
    exploreActivities: 'Explore Activities',
    selectLanguage: 'Select Language',
    errorFetchingCities: 'Failed to fetch cities. Please try again later.',
    errorFetchingUser: 'Failed to fetch user data. Please try again later.',
  },
  spanish: {
    selectCity: 'Seleccionar Ciudad',
    gps: 'GPS',
    points: 'Puntos',
    pointsInfo: '¡Usa tus puntos para obtener descuentos en ofertas increíbles! Haz clic abajo para explorar descuentos.',
    viewDiscounts: 'Ver Descuentos',
    earnPoints: 'Cómo Ganar Puntos',
    earnPointsInfo: [
      'Participa en actividades',
      'Síguenos en redes sociales',
      'Canta una canción en karaoke',
      'Déjanos una reseña',
    ],
    exploreActivities: 'Explorar Actividades',
    selectLanguage: 'Seleccionar Idioma',
    errorFetchingCities: 'No se pudieron obtener las ciudades. Por favor, inténtalo de nuevo más tarde.',
    errorFetchingUser: 'No se pudieron obtener los datos del usuario. Por favor, inténtalo de nuevo más tarde.',
  },
  polish: {
    selectCity: 'Wybierz Miasto',
    gps: 'GPS',
    points: 'Punkty',
    pointsInfo: 'Użyj swoich punktów, aby uzyskać zniżki na niesamowite oferty! Kliknij poniżej, aby je zobaczyć.',
    viewDiscounts: 'Zobacz Zniżki',
    earnPoints: 'Jak Zdobyć Punkty',
    earnPointsInfo: [
      'Weź udział w zajęciach',
      'Śledź nas w mediach społecznościowych',
      'Zaśpiewaj piosenkę na karaoke',
      'Zostaw nam recenzję',
    ],
    exploreActivities: 'Odkryj Aktywności',
    selectLanguage: 'Wybierz Język',
    errorFetchingCities: 'Nie udało się pobrać miast. Spróbuj ponownie później.',
    errorFetchingUser: 'Nie udało się pobrać danych użytkownika. Spróbuj ponownie później.',
  },
};

const NavElements = () => {
  const { language, setLanguage, options } = useLanguage();
  const { currentToken } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCityModalOpen, setIsCityModalOpen] = useState(false);
  const [isPointsModalOpen, setIsPointsModalOpen] = useState(false);
  const { selectedCity, isUsingGPS, setSelectedCity, fetchUserCity } = useLocationContext();
  const [cities, setCities] = useState<any[]>([]);
  const [userData, setUserData] = useState<any>(null);
  const navigate = useNavigate();
  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    if (currentToken) {
      fetchCities();
      fetchUserData();
    }
  }, [currentToken]);

  useEffect(() => {
    fetchUserCity();
  }, []);

  const fetchCities = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/city/cities`, data);
      if (response.ok) {
        const data = await response.json();
        setCities(data);
      } else {
        throw new Error(t.errorFetchingCities);
      }
    } catch {
      Swal.fire({
        title: 'Error',
        text: t.errorFetchingCities,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const fetchUserData = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/user`, data);
      if (response.ok) {
        const result = await response.json();
        setUserData(result);
      } else {
        throw new Error(t.errorFetchingUser);
      }
    } catch {
      Swal.fire({
        title: 'Error',
        text: t.errorFetchingUser,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const handleLanguageChange = (language: string) => {
    setLanguage(language);
    setIsModalOpen(false);
  };

  const handleLocationClick = () => {
    setIsCityModalOpen(true);
  };

  const handleCitySelection = async (cityId: string) => {
    localStorage.setItem('selectedCity', cityId);
    setSelectedCity(cityId);
    setIsCityModalOpen(false);
  };

  return (
    <>
      <div
        className="fixed left-0 top-0 w-full flex items-center justify-center flex-row h-16 py-10 bg-black"
        style={{ zIndex: 999 }}
      >
        <div className="ml-4 max-w-xs absolute right-2 flex flex-row items-center justify-end space-x-2">
          {currentToken && (
            <button
              onClick={handleLocationClick}
              className="text-white text-xs flex items-center justify-center p-1 px-3 rounded-lg h-8 bg-gray-800 hover:bg-gray-700 focus:outline-none transition-all duration-200"
            >
              <FaMapMarkerAlt className="text-lg mr-1" />
              {selectedCity ? (
                isUsingGPS ? (
                  <>
                    <span>{t.gps}</span>
                    <span className="w-2 h-2 bg-green-500 rounded-full ml-1"></span>
                  </>
                ) : cities.length && selectedCity !== 'Using GPS Location' ? (
                  <span>{cities.find((city) => city._id === selectedCity)?.name}</span>
                ) : (
                  <span>{selectedCity}</span>
                )
              ) : (
                <span>{t.selectCity}</span>
              )}
            </button>
          )}
          <button
            onClick={() => setIsModalOpen(true)}
            className="bg-gray-800 text-white text-xs p-1 rounded-lg px-3 h-8 hover:bg-gray-700 focus:outline-none transition-all duration-200"
          >
            {options.find((option) => option.value === language)?.flag || t.selectLanguage}
          </button>
          {userData && (
            <div className="flex items-center space-x-2">
              <div
                className="flex items-center space-x-1 cursor-pointer"
                onClick={() => setIsPointsModalOpen(true)}
              >
                <FaCoins className="text-yellow-500" />
                <span className="text-white text-sm">{userData.totalPoints || 0}</span>
              </div>
              <img
                onClick={() => navigate('/app/profile')}
                src={userData.profilePicture || userData.media?.[0] || '/default-avatar.png'}
                alt="Profile"
                className="w-8 h-8 rounded-full"
              />
            </div>
          )}
        </div>
      </div>

      {isCityModalOpen && (
        <CitySelectionModal onCitySelect={handleCitySelection} onClose={() => setIsCityModalOpen(false)} />
      )}

      <Modal
        isOpen={isPointsModalOpen}
        onRequestClose={() => setIsPointsModalOpen(false)}
        contentLabel={t.points}
        className="fixed inset-0 flex items-center justify-center z-50 py-8"
        overlayClassName="fixed inset-0 bg-black bg-opacity-80 z-40"
        style={{
          content: {
            top: '20%',
            left: '10%',
            right: '10%',
            bottom: '20%',
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '10px',
            padding: '20px',
          },
        }}
      >
        <div className="flex flex-col items-center">
          <h2 className="text-2xl mb-4">{t.points}</h2>
          <p className="text-sm mb-4">{t.pointsInfo}</p>
          <button
            className="bg-green-500 text-white py-2 px-4 rounded-lg mb-4 hover:bg-green-600"
            onClick={() => {navigate('/app/partyticket/discounts/66cc50ad5d4ada8c64b24616'); setIsPointsModalOpen(false)}}
          >
            {t.viewDiscounts}
          </button>
          <h3 className="text-lg font-semibold mb-2">{t.earnPoints}</h3>
          <ul className="list-disc list-inside text-sm text-gray-400 mb-4">
            {t.earnPointsInfo.map((info, index) => (
              <li key={index}>{info}</li>
            ))}
          </ul>
          <button
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
            onClick={() => {navigate('/app/partyticket/promotions/66cc50ad5d4ada8c64b24616'); setIsPointsModalOpen(false)}}
          >
            {t.exploreActivities}
          </button>
        </div>
      </Modal>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel={t.selectLanguage}
        className="fixed inset-0 flex items-center justify-center z-50 py-8"
        overlayClassName="fixed inset-0 bg-black bg-opacity-80 z-40"
        style={{
          content: {
            top: '20%',
            left: '10%',
            right: '10%',
            bottom: '20%',
            backgroundColor: 'black',
            color: 'white',
            borderRadius: '10px',
            padding: '20px',
          },
        }}
      >
        <div className="flex flex-col items-center">
          <h2 className="text-2xl mb-4">{t.selectLanguage}</h2>
          <ul className="flex flex-col space-y-2 w-full">
            {options.map((option) => (
              <li key={option.value} className="w-full">
                <button
                  onClick={() => handleLanguageChange(option.value)}
                  className="bg-gray-800 hover:bg-gray-600 text-white py-2 px-4 rounded-lg w-full text-left"
                >
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default NavElements;
