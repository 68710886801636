import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLocationContext } from 'Contexts/LocationContext'; // Import LocationContext
import { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import EventDetail from '../EventDetail';
import PartyTicket from '../PartyTicket';
import ShareHandler from '../ShareHandler';
import Admin from './Admin';
import DetailView from './Admin/DetailView';
import CheckIn from './CheckIn'; // Import the new PointsList component
import CitySelectionModal from './CitySelectionModal';
import CreateTakiTakiEvents from './CreateTakiTakiEvents';
import EditProfile from './EditProfile';
import Events from './Events';
import Home from './Home';
import PointsList from './PointsList'; // Import the new PointsList component
import Profile from './Profile';
import QRScan from './QRScan/index.jsx'; // Import the QR Scan component

const Intranet: React.FC = () => {
  const { currentToken, expiration, clearAuthentication } = useContext(AuthContext);
  const { setSelectedCity, fetchUserCity } = useLocationContext(); // Use LocationContext
  const [user, setUser] = useState<any>(null);
  const [isCityModalOpen, setIsCityModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentToken) {
      getUser();
    }
    fetchUserCity();
  }, [currentToken]);

  useEffect(() => {
    const selectedCity = localStorage.getItem('selectedCity');
    if (!selectedCity) {
      setIsCityModalOpen(true);
    }
  }, []);

  const getUser = async (username?: string) => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    const endpoint = username ? `public/user/${username}` : 'client/user';
    await fetch(`${APIBase}/${endpoint}`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        setUser(responseJson);
        if (!responseJson.selectedCity) {
          setIsCityModalOpen(true);
        }
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Please try again later 😞',
          showConfirmButton: false,
          timer: 3000,
        });
      });
    return true;
  };

  const handleCitySelection = async (cityId: string) => {
    localStorage.setItem('selectedCity', cityId);
    setSelectedCity(cityId); // Update the context with the selected city

    const data: RequestInit = {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedCity: cityId }),
    };

    await fetch(`${APIBase}/client/user`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then(() => {
        setIsCityModalOpen(false);
        getUser();
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to update city. Please try again later.',
          icon: 'error',
          timer: 3000,
        });
      });
  };

  if (!currentToken) {
    Swal.fire({
      title: 'Login Required',
      text: 'Please login to access this section.',
      showConfirmButton: false,
      background: '#000000',
      color: '#FFFFFF',
    });
  } else if (parseInt(expiration) - Date.now() < 1000 || !expiration) {
    Swal.fire({
      title: 'Session Time is Over',
      text: 'Your session time is over. Please login again.',
      showConfirmButton: false,
      background: '#000000',
      color: '#FFFFFF',
    });
  }

  const isAuthenticated = currentToken && expiration && parseInt(expiration) - Date.now() > 1000;
  !isAuthenticated && clearAuthentication();

  if (!isAuthenticated) navigate('/auth/login');

  return (
    <>
      <Routes>
        <Route path="profile" element={<Profile />} />
        <Route path="home" element={<Home />} />
        <Route path="profile/edit" element={<EditProfile />} />
        <Route path="profile/post/:postId" element={<DetailView user={user} getUser={getUser} />} />
        <Route path="my-events" element={<Events />} />
        <Route path="admin/*" element={<Admin />} />
        <Route path="partyticket/*" element={<PartyTicket />} />
        <Route path="partyticket/share" element={<ShareHandler />} />
        <Route path="partyticket/event/:eventId" element={<EventDetail />} />
        <Route path="create-taki-taki-events" element={<CreateTakiTakiEvents />} />
        <Route path="scan-qr" element={<QRScan />} /> {/* New Route for QR Scan */}
        <Route path="check-in" element={<CheckIn />} /> {/* New Route for QR Scan */}
        <Route path="points" element={<PointsList />} /> {/* New Route for Points List */}
        <Route path="*" element={<Navigate to="/app/profile" />} />
      </Routes>
      {isCityModalOpen && (
        <CitySelectionModal onCitySelect={handleCitySelection} onClose={() => setIsCityModalOpen(false)} />
      )}
    </>
  );
};

export default Intranet;
