import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import Swal from 'sweetalert2';
import Modal from 'react-modal';
import QRCode from 'react-qr-code';
import { FaChevronLeft, FaQrcode } from 'react-icons/fa';
import { APIBase } from 'api/hosts';

const translations = {
  english: {
    activities: 'Activities',
    back: 'Back to Venue',
    name: 'Name',
    description: 'Description',
    points: 'Points',
    noActivities: 'No activities available at this venue.',
    error: 'Error',
    fetchError: 'Failed to load activities. Please try again later.',
    qrCode: 'QR Code',
    close: 'Close',
  },
  spanish: {
    activities: 'Actividades',
    back: 'Volver al Lugar',
    name: 'Nombre',
    description: 'Descripción',
    points: 'Puntos',
    noActivities: 'No hay actividades disponibles en este lugar.',
    error: 'Error',
    fetchError: 'No se pudieron cargar las actividades. Inténtalo de nuevo más tarde.',
    qrCode: 'Código QR',
    close: 'Cerrar',
  },
  polish: {
    activities: 'Zajęcia',
    back: 'Powrót do Miejsca',
    name: 'Nazwa',
    description: 'Opis',
    points: 'Punkty',
    noActivities: 'Brak dostępnych zajęć w tym miejscu.',
    error: 'Błąd',
    fetchError: 'Nie udało się załadować zajęć. Spróbuj ponownie później.',
    qrCode: 'Kod QR',
    close: 'Zamknij',
  },
};

const ActivitiesView: React.FC = () => {
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const { barId } = useParams<{ barId: string }>();
  const navigate = useNavigate();
  const [activities, setActivities] = useState<any[]>([]);
  const [selectedActivity, setSelectedActivity] = useState<any>(null);
  const [qrModalOpen, setQrModalOpen] = useState(false);
    const [userData, setUserData] = useState<any>(null);

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchActivities();
    fetchUserData();
  }, [barId, currentToken]);

  const fetchActivities = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/activity/venue/${barId}`, data);
      if (response.ok) {
        const result = await response.json();
        setActivities(result.activities);
      } else {
        throw new Error(t.fetchError);
      }
    } catch {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const openQrModal = (activity: any) => {
    setSelectedActivity(activity);
    setQrModalOpen(true);
  };

  const closeQrModal = () => {
    setQrModalOpen(false);
    setSelectedActivity(null);
  };


  const fetchUserData = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    const endpoint = 'client/user';
    await fetch(`${APIBase}/${endpoint}`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        setUserData(responseJson);
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  return (
    <div className="text-white min-h-screen bg-gradient-to-b from-black via-gray-900 to-black p-4 pt-20 pb-28">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="fixed top-20 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out"
      >
        <FaChevronLeft className="text-xl" />
      </button>

      {/* Main Content */}
      <div className="max-w-3xl mx-auto mt-16">
        <h2 className="text-3xl font-bold mb-6 text-center">{t.activities}</h2>

        {activities.length > 0 ? (
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            {activities.map((activity) => (
              <div
                key={activity._id}
                className="bg-gray-800 p-4 rounded-lg shadow-lg flex items-center cursor-pointer hover:bg-gray-700 transition-all duration-300 ease-in-out"
                onClick={() => openQrModal(activity)}
              >
                <FaQrcode className="text-green-400 text-3xl mr-4" />
                <div>
                  <h4 className="text-lg font-semibold">{activity.name}</h4>
                  <p className="text-sm text-gray-400">{activity.description || t.points}</p>
                  <p className="text-sm text-gray-500">
                    {activity.points} {t.points.toLowerCase()}
                  </p>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-400">{t.noActivities}</p>
        )}
      </div>

      {/* QR Code Modal */}
      {selectedActivity && (
        <Modal
          isOpen={qrModalOpen}
          onRequestClose={closeQrModal}
          className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-75"
        >
          <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-sm mx-auto text-white">
            <h2 className="text-2xl mb-4">{t.qrCode}</h2>
            <div className="mb-4">
              <p className="text-lg">
                <strong>{t.name}:</strong> {selectedActivity.name}
              </p>
              <p className="text-sm text-gray-400">
                <strong>{t.description}:</strong> {selectedActivity.description || t.points}
              </p>
              <p className="text-sm text-gray-400">
                <strong>{t.points}:</strong> {selectedActivity.points}
              </p>
            </div>
            <div className="flex justify-center bg-white p-5 rounded-lg">
              <QRCode
                value={JSON.stringify({
                  activityId: selectedActivity._id,
                  pointsUsed: selectedActivity.points,
                  venueId: barId,
                  userId: userData._id,
                })}
                size={200}
                bgColor="#ffffff"
                fgColor="#000000"
              />
            </div>
            <button
              onClick={closeQrModal}
              className="w-full mt-4 py-2 bg-blue-600 text-white rounded-lg shadow-md focus:outline-none hover:bg-blue-500"
            >
              {t.close}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ActivitiesView;
