import { faHome, faMicrophone, faMusic, faTags, faPercentage, faCoins } from '@fortawesome/free-solid-svg-icons'; // Added faCoins for Earn Coins
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const LowerBar: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { language } = useLanguage();

  const translations = {
    english: {
      home: 'Home',
      party: 'Party',
      karaoke: 'Karaoke',
      earnCoins: 'Earn Coins',
      discounts: 'Discounts',
    },
    spanish: {
      home: 'Inicio',
      party: 'Fiestas',
      karaoke: 'Karaoke',
      earnCoins: 'Gana Monedas',
      discounts: 'Descuentos',
    },
    polish: {
      home: 'Strona główna',
      party: 'Impreza',
      karaoke: 'Karaoke',
      earnCoins: 'Zarabiaj Monety',
      discounts: 'Zniżki',
    },
  };

  const t = translations[language as keyof typeof translations];

  const getButtonClass = (paths: string[]) => {
    return paths.some((path) => location.pathname.startsWith(path))
      ? 'text-neon-blue border-b-2 border-neon-blue'
      : 'text-white';
  };

  return (
    <div
      className="fixed bottom-0 left-0 w-full bg-gradient-to-r from-black via-gray-900 to-black text-white flex justify-around items-center px-4 py-3 shadow-lg z-50"
      style={{ paddingBottom: 'calc(env(safe-area-inset-bottom) + 8px)' }}
    >
      <div className="flex flex-row justify-between items-center w-full max-w-3xl mx-auto space-x-4">
        {/* Party Button */}
        <button
          onClick={() => navigate('/app/partyticket/events')}
          className={`flex flex-col items-center justify-center w-16 h-16 ${getButtonClass([
            '/app/partyticket/events',
          ])} hover:text-neon-blue transition-all duration-300 ease-in-out`}
        >
          <FontAwesomeIcon icon={faMusic} className="text-2xl" />
          <span className="text-xs mt-1">{t.party}</span>
        </button>

        {/* Karaoke Button */}
        <button
          onClick={() => navigate('/app/partyticket/rooms/66cc50ad5d4ada8c64b24616')}
          className={`flex flex-col items-center justify-center w-16 h-16 ${getButtonClass([
            '/app/partyticket/rooms/66cc50ad5d4ada8c64b24616',
          ])} hover:text-neon-blue transition-all duration-300 ease-in-out`}
        >
          <FontAwesomeIcon icon={faMicrophone} className="text-2xl" />
          <span className="text-xs mt-1">{t.karaoke}</span>
        </button>

        {/* Center Home Button */}
        <button
          onClick={() => navigate('/app/home')}
          className="flex flex-col items-center justify-center w-20 h-20 bg-gradient-to-r p-3 rounded-full shadow-lg border border-neon-blue transition-all duration-300 ease-in-out"
        >
          <FontAwesomeIcon icon={faHome} className="text-3xl text-neon-blue" />
        </button>

        {/* Earn Coins Button */}
        <button
          onClick={() => navigate('/app/partyticket/earn-coins/66cc50ad5d4ada8c64b24616')}
          className={`flex flex-col items-center justify-center w-16 h-16 ${getButtonClass([
            '/app/partyticket/earn-coins',
          ])} hover:text-neon-blue transition-all duration-300 ease-in-out`}
        >
          <FontAwesomeIcon icon={faCoins} className="text-2xl" />
          <span className="text-xs mt-1">{t.earnCoins}</span>
        </button>

        {/* Discounts Button */}
        <button
          onClick={() => navigate('/app/partyticket/discounts/66cc50ad5d4ada8c64b24616')}
          className={`flex flex-col items-center justify-center w-16 h-16 ${getButtonClass([
            '/app/partyticket/discounts',
          ])} hover:text-neon-blue transition-all duration-300 ease-in-out`}
        >
          <FontAwesomeIcon icon={faPercentage} className="text-2xl" />
          <span className="text-xs mt-1">{t.discounts}</span>
        </button>
      </div>
    </div>
  );
};

export default LowerBar;
