import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaChevronLeft, FaCoins, FaDoorOpen, FaMapMarkerAlt, FaQrcode } from 'react-icons/fa';
import Modal from 'react-modal';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const translations = {
  english: {
    details: 'Bar Details',
    name: 'Name',
    address: 'Address',
    description: 'Description',
    schedule: 'Schedule',
    menuItems: 'Menu',
    viewOnMap: 'View on Map',
    activities: 'Activities',
    pointsEarned: 'Points Earned', // For activities
    pointsRequired: 'Points Required', // For menu items
    priceWithDiscount: 'Price with Discount', // Updated
    activityPoints: 'Activity Points',
    back: 'Back to Bars',
    error: 'Error',
    fetchError: 'Failed to load bar details. Please try again later.',
    discounts: 'Discounts',
    discountButton: 'Show QR for Discount',
    close: 'Close',
    rooms: 'Rooms',
  },
  spanish: {
    details: 'Detalles del Lugar',
    name: 'Nombre',
    address: 'Dirección',
    description: 'Descripción',
    schedule: 'Horario',
    menuItems: 'Menú',
    viewOnMap: 'Ver en el Mapa',
    activities: 'Actividades',
    pointsEarned: 'Puntos Ganados', // For activities
    pointsRequired: 'Puntos Requeridos', // For menu items
    priceWithDiscount: 'Precio con Descuento', // Updated
    activityPoints: 'Puntos de la Actividad',
    back: 'Volver a los Bares',
    error: 'Error',
    fetchError: 'No se pudieron cargar los detalles del bar. Inténtalo de nuevo más tarde.',
    discounts: 'Descuentos',
    discountButton: 'Mostrar QR para el Descuento',
    close: 'Cerrar',
    rooms: 'Rooms',
  },
  polish: {
    details: 'Szczegóły Miejsca',
    name: 'Nazwa',
    address: 'Adres',
    description: 'Opis',
    schedule: 'Harmonogram',
    menuItems: 'Menu',
    viewOnMap: 'Zobacz na Mapie',
    activities: 'Zajęcia',
    pointsEarned: 'Punkty Zdobyte', // For activities
    pointsRequired: 'Wymagane Punkty', // For menu items
    priceWithDiscount: 'Cena ze Zniżką', // Updated
    activityPoints: 'Punkty za Zajęcie',
    back: 'Powrót do Miejsc',
    error: 'Błąd',
    fetchError: 'Nie udało się załadować szczegółów baru. Spróbuj ponownie później.',
    discounts: 'Zniżki',
    discountButton: 'Pokaż QR dla Zniżki',
    close: 'Zamknij',
    rooms: 'Rooms',
  },
};

const BarDetail: React.FC = () => {
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const { barId } = useParams<{ barId: string }>();
  const navigate = useNavigate();
  const [bar, setBar] = useState<any | null>(null);
  const [menuItems, setMenuItems] = useState<any[]>([]);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [userData, setUserData] = useState<any>(null);
  const [rooms, setRooms] = useState<any[]>([]);
  const t = translations[language as keyof typeof translations];

  const [activities, setActivities] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  useEffect(() => {
    fetchBarDetails();
    fetchMenuItems();
    fetchUserData();
    fetchActivities();
    fetchRooms();
  }, [barId, currentToken]);

  const fetchRooms = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/room/byVenueId/${barId}`, data);
      if (response.ok) {
        const result = await response.json();
        setRooms(result.rooms);
      } else {
        throw new Error(t.fetchError);
      }
    } catch {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const fetchUserData = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    const endpoint = 'client/user';
    await fetch(`${APIBase}/${endpoint}`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((responseJson) => {
        setUserData(responseJson);
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const fetchActivities = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/activity/venue/${barId}`, data)
      .then((response) => response.json())
      .then((responseJson) => setActivities(responseJson.activities))
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const fetchBarDetails = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/venue/${barId}`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.error);
        }
      })
      .then((responseJson) => {
        setBar(responseJson);
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const fetchMenuItems = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/menu-item?venue=${barId}`, data)
      .then((response) => response.json())
      .then((responseJson) => setMenuItems(responseJson))
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const handleViewOnMap = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const mapLink = isIOS
      ? `http://maps.apple.com/?q=${encodeURIComponent(bar.address)}`
      : `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(bar.address)}`;
    window.open(mapLink, '_blank');
  };

  const openQrModal = (item: any) => {
    setSelectedItem(item);
    setQrModalOpen(true);
  };

  const closeQrModal = () => {
    setQrModalOpen(false);
    setSelectedItem(null);
  };

  return (
    <div className="text-white min-h-screen bg-gradient-to-b from-black via-gray-900 to-black p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="fixed top-20 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out"
      >
        <FaChevronLeft className="text-xl" />
      </button>
      {bar ? (
        <div className="max-w-3xl mx-auto mt-16">
          <h2 className="text-3xl font-bold mb-6">{t.details}</h2>
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
            <p className="text-lg mb-4">
              <strong>{t.name}:</strong> {bar.name}
            </p>
            <p className="text-lg mb-4">
              <strong>{t.address}:</strong> {bar.address}
            </p>
            <p className="text-lg mb-4">
              <strong>{t.description}:</strong> {bar.description}
            </p>
            <button
              onClick={handleViewOnMap}
              className="flex items-center bg-gradient-to-r from-blue-600 to-blue-800 text-white p-3 rounded-full shadow-lg hover:from-blue-700 hover:to-blue-900 transition-all duration-300 ease-in-out mb-6"
            >
              <FaMapMarkerAlt className="mr-2 text-xl" />
              {t.viewOnMap}
            </button>
            {bar.imageURL && (
              <img src={bar.imageURL} alt={bar.name} className="w-full h-64 object-cover rounded-md mb-6" />
            )}
            {bar.schedule && (
              <div className="mt-6">
                <h3 className="text-2xl font-semibold mb-4">{t.schedule}</h3>
                <div className="space-y-2">
                  {bar.schedule.map((day: any, index: number) => (
                    <div key={index} className="flex justify-between text-lg">
                      <span>{day.day}</span>
                      <span>
                        {day.times.length > 0
                          ? day.times.map((time: any) => `${time.open} - ${time.close}`).join(', ')
                          : 'Closed'}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          {rooms.length > 0 && (
            <div className="mt-8">
              <h3 className="text-2xl font-semibold mb-4">{t.rooms}</h3>
              <div className="space-y-4">
                {rooms.map((room) => (
                  <div
                    key={room._id}
                    className="bg-gray-800 p-4 rounded-lg shadow-lg flex items-center cursor-pointer"
                    onClick={() => navigate(`/app/partyticket/room/${room._id}`)}
                  >
                    <FaDoorOpen className="text-green-400 text-xl mr-4" />
                    <div>
                      <h4 className="text-lg font-semibold">{room.name}</h4>
                      <p className="text-sm text-gray-400">{room.capacity} people capacity</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {activities.length > 0 && (
            <div className="mt-8">
              <h3 className="text-2xl font-semibold mb-4">{t.activities}</h3>
              <div className="space-y-4">
                {activities.map((activity) => (
                  <div
                    key={activity._id}
                    className="bg-gray-800 p-4 rounded-lg shadow-lg flex items-center cursor-pointer"
                    onClick={() => openQrModal({ activity, bar })}
                  >
                    <div className="flex-grow">
                      <h4 className="text-lg font-semibold">{activity.name}</h4>
                      <p className="text-sm text-gray-400">{activity.description || t.pointsRequired}</p>
                      <p className="text-sm text-gray-500">{activity.points} points</p>
                    </div>
                    <FaQrcode className="text-green-400 text-xl" />
                  </div>
                ))}
              </div>
            </div>
          )}

          {menuItems.length > 0 && (
            <div className="mt-8">
              <h3 className="text-2xl font-semibold mb-4">{t.menuItems}</h3>
              <div className="space-y-4">
                {menuItems.map((menuItem) => (
                  <div
                    key={menuItem._id}
                    className="bg-gray-800 p-4 rounded-lg shadow-lg flex items-start cursor-pointer flex-col"
                    onClick={() => navigate(`/app/partyticket/menu-item/${menuItem._id}`)}
                  >
                    <div className="flex flex-row">
                      {menuItem.imageURL && (
                        <img
                          src={menuItem.imageURL}
                          alt={menuItem.name}
                          className="w-16 h-16 object-cover rounded-md mr-4"
                        />
                      )}
                      <div className="flex-grow">
                        <h4 className="text-lg font-semibold">{menuItem.name}</h4>
                        <p className="text-sm text-gray-400">{menuItem.description}</p>
                        <p className="text-sm text-gray-500">
                          {menuItem.price} {menuItem.currency}
                        </p>
                      </div>
                    </div>
                    {/* Show Discounts */}
                    {menuItem.discounts?.length > 0 && (
                      <div className="mt-2 w-full">
                        <h5 className="text-sm font-semibold text-green-400">{t.discounts}:</h5>
                        <div className="flex flex-row space-x-1">
                          {menuItem.discounts.map((discount: any, index: number) => (
                            <div
                              key={index}
                              onClick={(e) => {
                                e.stopPropagation();
                                openQrModal({ discount, menuItem, bar });
                              }}
                              className="cursor-pointer bg-gray-800 hover:bg-gray-700 transition-all duration-300 ease-in-out p-3 rounded-lg flex justify-between items-center mt-2 shadow-lg border border-green-400 flex-col"
                            >
                              <div className="flex items-center space-x-4">
                                <FaQrcode className="text-green-400 text-xl" />
                                <div className="text-white text-lg font-semibold mr-2">
                                  {discount.priceWithDiscount} {menuItem.currency} {/* Updated */}
                                </div>
                              </div>
                              <div className="text-sm text-gray-300 pl-auto flex flex-row w-full items-center justify-end">
                                -{discount.pointsRequired} <FaCoins className="ml-2" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <p>{t.fetchError}</p>
      )}

      {/* QR Code Modal */}
      {selectedItem && userData && (
        <Modal
          isOpen={qrModalOpen}
          onRequestClose={closeQrModal}
          className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-75"
        >
          <div className="w-full max-w-md bg-black p-6 rounded-lg shadow-lg">
            <h2 className="text-2xl mb-4 text-white">QR Code</h2>
            <div className="mb-4 text-white">
              {selectedItem.activity ? (
                <>
                  <p>
                    <strong>{t.name}:</strong> {selectedItem.activity.name}
                  </p>
                  <p>
                    {t.pointsEarned}: {selectedItem.activity.points} {t.activityPoints.toLowerCase()}
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <strong>{t.name}:</strong> {selectedItem.menuItem.name}
                  </p>
                  <p>
                    {t.pointsRequired}: {selectedItem.discount.pointsRequired}
                  </p>
                  <p>
                    {t.priceWithDiscount}: {selectedItem.discount.priceWithDiscount} {/* Updated */}
                  </p>
                </>
              )}
            </div>
            <div className="flex justify-center mb-4 bg-white p-5">
              <QRCode
                value={JSON.stringify({
                  venueId: selectedItem.bar._id,
                  menuItemId: selectedItem.menuItem?._id,
                  activityId: selectedItem.activity?._id,
                  pointsUsed: selectedItem.discount?.pointsRequired || selectedItem.activity.points,
                  priceWithDiscount: selectedItem.discount?.priceWithDiscount, // Updated
                  userId: userData._id,
                })}
                size={200}
                bgColor="#ffffff"
                fgColor="#000000"
              />
            </div>
            <button
              onClick={closeQrModal}
              className="w-full py-2 bg-blue-600 text-white rounded-lg border border-solid border-white shadow-md focus:outline-none hover:bg-blue-500"
            >
              {t.close}
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BarDetail;
