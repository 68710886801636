import React, { useState, useContext, ChangeEvent, FormEvent } from 'react';
import Swal from 'sweetalert2';
import { APIBase } from 'api/hosts';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'Contexts/Auth';
import { ClapSpinner } from 'react-spinners-kit';
import { Link } from 'react-router-dom';
import { useLanguage } from 'Contexts/LanguageContext';

const translations = {
  english: {
    login: 'Login',
    email: 'Email Address',
    password: 'Password',
    register: 'Register',
    forgotPassword: 'Forgot password?',
    invalidPassword: 'Invalid password format.',
    passwordCriteria: 'Must contain at least 1 number, 1 uppercase, 1 special character, and between 8 and 15 characters.',
    notFoundUser: 'User not found. Please register.',
    incorrectCredentials: 'Incorrect email or password.',
    welcome: 'Welcome to Taki 🗿',
    loading: 'Logging in...',
    networkError: 'Network Error. Please try again.',
  },
  spanish: {
    login: 'Iniciar Sesión',
    email: 'Correo Electrónico',
    password: 'Contraseña',
    register: 'Registrarse',
    forgotPassword: '¿Olvidaste tu contraseña?',
    invalidPassword: 'Formato de contraseña inválido.',
    passwordCriteria: 'Debe contener al menos 1 número, 1 mayúscula, 1 carácter especial, y entre 8 y 15 caracteres.',
    notFoundUser: 'Usuario no encontrado. Por favor regístrese.',
    incorrectCredentials: 'Correo o contraseña incorrectos.',
    welcome: 'Bienvenido a Taki 🗿',
    loading: 'Iniciando sesión...',
    networkError: 'Error de red. Por favor, inténtalo de nuevo.',
  },
  polish: {
    login: 'Zaloguj się',
    email: 'Adres Email',
    password: 'Hasło',
    register: 'Rejestracja',
    forgotPassword: 'Zapomniałeś hasła?',
    invalidPassword: 'Nieprawidłowy format hasła.',
    passwordCriteria: 'Musi zawierać co najmniej 1 cyfrę, 1 wielką literę, 1 znak specjalny i mieć długość od 8 do 15 znaków.',
    notFoundUser: 'Nie znaleziono użytkownika. Zarejestruj się.',
    incorrectCredentials: 'Nieprawidłowy email lub hasło.',
    welcome: 'Witamy w Taki 🗿',
    loading: 'Logowanie...',
    networkError: 'Błąd sieci. Spróbuj ponownie.',
  },
};

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { currentToken, expiration, updateToken, updateExpiration } = useContext(AuthContext);
  const { language } = useLanguage(); // Language context
  const t = translations[language as keyof typeof translations];

  const isAuthenticated = currentToken && expiration && parseInt(expiration) - Date.now() > 1000;

  if (isAuthenticated) navigate('/app/home');

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();

    const checker = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
    if (!password.match(checker)) {
      Swal.fire({
        title: t.invalidPassword,
        text: t.passwordCriteria,
        showConfirmButton: false,
        background: '#000000',
        color: '#FFFFFF',
      });
      setLoading(false);
      return;
    }

    const body = { email, password };
    const data: RequestInit = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };

    try {
      const response = await fetch(`${APIBase}/auth/login`, data);
      const responseJson = await response.json();

      if (responseJson.type === 'not-found-user') {
        Swal.fire({
          title: t.notFoundUser,
          showConfirmButton: false,
          timer: 3000,
          background: '#000000',
          color: '#FFFFFF',
        });
        setLoading(false);
        return;
      }

      if (responseJson.type === 'incorrect-credentials') {
        Swal.fire({
          title: t.incorrectCredentials,
          showConfirmButton: false,
          timer: 3000,
          background: '#000000',
          color: '#FFFFFF',
        });
        setLoading(false);
        return;
      }

      Swal.fire({
        title: t.welcome,
        showConfirmButton: false,
        timer: 3000,
        background: '#000000',
        color: '#FFFFFF',
      });

      updateToken(responseJson.token);
      updateExpiration(responseJson.dateExpiration);
      navigate('/app/home');
    } catch (error) {
      Swal.fire({
        title: t.networkError,
        showConfirmButton: false,
        timer: 3000,
        background: '#000000',
        color: '#FFFFFF',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black p-6">
      <div className="w-full max-w-sm bg-black bg-opacity-70 rounded-lg shadow-md p-6">
        <h1 className="text-4xl text-white font-bold mb-6">{t.login}</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          <input
            type="email"
            placeholder={t.email}
            value={email}
            onChange={handleChangeEmail}
            className="w-full px-3 py-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none"
            required
          />
          <input
            type="password"
            placeholder={t.password}
            value={password}
            onChange={handleChangePassword}
            className="w-full px-3 py-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none"
            title={t.passwordCriteria}
            required
          />
          <button
            type="submit"
            className="w-full py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 flex items-center justify-center"
          >
            {loading ? <ClapSpinner size={15} color="#FFFFFF" /> : t.login}
          </button>
        </form>
        <div className="flex justify-between mt-4">
          <Link to="/auth/register" className="text-sm text-gray-400 hover:text-white">
            {t.register}
          </Link>
          <Link to="/auth/recover" className="text-sm text-gray-400 hover:text-white">
            {t.forgotPassword}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
