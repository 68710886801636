import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from 'Contexts/Auth';
import { APIBase } from 'api/hosts';
import { useLanguage } from 'Contexts/LanguageContext';
import Swal from 'sweetalert2';
import { FaChevronLeft, FaMusic } from 'react-icons/fa';

const RoomDetail: React.FC = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage(); // Get current language
  const navigate = useNavigate();
  const [room, setRoom] = useState<any>(null);
  const [playlists, setPlaylists] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const translations = {
    english: {
      back: 'Back',
      playlists: 'Playlists',
      noPlaylists: 'No playlists in this room.',
      loading: 'Loading...',
      roomNotFound: 'Room not found.',
      error: 'Error',
      fetchRoomError: 'Could not fetch room details.',
      fetchPlaylistsError: 'Could not fetch playlists.',
      videos: 'videos',
      newest: 'Newest',
    },
    spanish: {
      back: 'Volver',
      playlists: 'Listas de Reproducción',
      noPlaylists: 'No hay listas de reproducción en esta sala.',
      loading: 'Cargando...',
      roomNotFound: 'Sala no encontrada.',
      error: 'Error',
      fetchRoomError: 'No se pudieron obtener los detalles de la sala.',
      fetchPlaylistsError: 'No se pudieron obtener las listas de reproducción.',
      videos: 'videos',
      newest: 'Más Reciente',
    },
    polish: {
      back: 'Powrót',
      playlists: 'Listy Odtwarzania',
      noPlaylists: 'Brak list odtwarzania w tej sali.',
      loading: 'Ładowanie...',
      roomNotFound: 'Nie znaleziono sali.',
      error: 'Błąd',
      fetchRoomError: 'Nie udało się pobrać szczegółów sali.',
      fetchPlaylistsError: 'Nie udało się pobrać list odtwarzania.',
      videos: 'wideo',
      newest: 'Najnowsze',
    },
  };

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    if (roomId) {
      fetchRoomDetails();
      fetchPlaylists();
    }
  }, [roomId]);

  const fetchRoomDetails = async () => {
    try {
      const response = await fetch(`${APIBase}/client/room/${roomId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setRoom(data.room);
      } else {
        throw new Error('Failed to fetch room details');
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.fetchRoomError,
        icon: 'error',
      });
    }
  };

  const fetchPlaylists = async () => {
    try {
      const response = await fetch(`${APIBase}/client/playlist/byRoomId/${roomId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        const sortedPlaylists = data.playlists.sort(
          (a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
        setPlaylists(sortedPlaylists);
      } else {
        throw new Error('Failed to fetch playlists');
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.fetchPlaylistsError,
        icon: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <p>{t.loading}</p>
      </div>
    );
  }

  if (!room) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <p>{t.roomNotFound}</p>
      </div>
    );
  }

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 py-20">
      <button
        onClick={() => navigate(-1)}
        className="top-20 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out mb-3"
      >
        <FaChevronLeft className="text-xl" />
      </button>
      <h2 className="text-4xl font-extrabold tracking-tight mb-6">{room.name}</h2>
      <div className="bg-gray-800 p-6 rounded-lg shadow-md">
        <h3 className="text-2xl font-semibold mb-4">{t.playlists}</h3>
        {playlists.length > 0 ? (
          <ul className="space-y-4">
            {playlists.map((playlist, index) => (
              <li
                key={playlist._id}
                className={`flex items-center justify-between p-4 rounded-lg cursor-pointer transition ${
                  index === 0 ? 'bg-green-600 hover:bg-green-500' : 'bg-gray-700 hover:bg-gray-600'
                }`}
                onClick={() => navigate(`/app/partyticket/playlist/${playlist._id}`)}
              >
                <div>
                  <p className="font-bold">
                    {playlist.name} {index === 0 && <span className="text-xs text-white ml-2">({t.newest})</span>}
                  </p>
                  <p className="text-sm text-gray-300">{playlist.videos.length} {t.videos}</p>
                </div>
                <FaMusic className="text-white text-xl" />
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-400">{t.noPlaylists}</p>
        )}
      </div>
    </div>
  );
};

export default RoomDetail;
