import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import LowerBar from 'PartyTicket/LowerBar';
import { useContext, useState } from 'react';
import { QrReader } from 'react-qr-reader'; // Use @react-qr-reader for camera selection
import { useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit'; // Importing a loading spinner
import Swal from 'sweetalert2';
import { FaArrowLeft } from 'react-icons/fa'; // Importing home icon

const translations = {
  english: {
    title: 'Club Check-In',
    instruction: 'Scan the QR code located on the screens of the bar to check in and earn 10 points!',
    detectedVenue: 'Venue detected',
    detectedEvent: 'Event detected',
    claimPoints: 'Claim my points +10',
    rescan: 'Re-scan QR Code',
    cameraError: 'Camera access denied or not available. Please check your permissions.',
    processing: 'Processing QR Code...',
    alreadyCheckedIn: 'You can only check in once every 12 hours.',
    venueNotFound: 'Venue not found. Please try again.',
    userNotFound: 'User not found. Please log in again.',
    genericError: 'Failed to process the QR code. Please try again.',
  },
  spanish: {
    title: 'Registro en el Club',
    instruction: 'Escanea el código QR ubicado en las pantallas del bar para registrarte y ganar 10 puntos!',
    detectedVenue: 'Lugar detectado',
    detectedEvent: 'Evento detectado',
    claimPoints: 'Reclama mis puntos +10',
    rescan: 'Volver a escanear',
    cameraError: 'Acceso a la cámara denegado o no disponible. Verifique sus permisos.',
    processing: 'Procesando código QR...',
    alreadyCheckedIn: 'Solo puedes registrarte una vez cada 12 horas.',
    venueNotFound: 'Lugar no encontrado. Por favor, inténtelo de nuevo.',
    userNotFound: 'Usuario no encontrado. Por favor, inicie sesión de nuevo.',
    genericError: 'No se pudo procesar el código QR. Por favor, inténtelo de nuevo.',
  },
  polish: {
    title: 'Rejestracja w klubie',
    instruction: 'Zeskanuj kod QR znajdujący się na ekranach baru, aby się zameldować i zdobyć 10 punktów!',
    detectedVenue: 'Wykryto lokal',
    detectedEvent: 'Wykryto wydarzenie',
    claimPoints: 'Odbierz moje punkty +10',
    rescan: 'Zeskanuj ponownie',
    cameraError: 'Brak dostępu do kamery lub kamera niedostępna. Sprawdź swoje uprawnienia.',
    processing: 'Przetwarzanie kodu QR...',
    alreadyCheckedIn: 'Możesz zameldować się tylko raz na 12 godzin.',
    venueNotFound: 'Nie znaleziono lokalu. Spróbuj ponownie.',
    userNotFound: 'Nie znaleziono użytkownika. Zaloguj się ponownie.',
    genericError: 'Nie udało się przetworzyć kodu QR. Spróbuj ponownie.',
  },
};

const QRScan = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cameraError, setCameraError] = useState(false);
  const [scannedData, setScannedData] = useState(null);
  const [reScan, setReScan] = useState(false);
  const { currentToken } = useContext(AuthContext);
  const { language } = useContext(AuthContext); // Assume language context is available
  const t = translations[language || 'english']; // Default to English
  const navigate = useNavigate();

  const processScan = async () => {
    setLoading(true);

    try {
      let endpoint = '';
      let id = '';

      if (scannedData.text.startsWith('venue-')) {
        endpoint = '/client/venue/scan-qr';
        id = scannedData.text.replace('venue-', '');
      } else if (scannedData.text.startsWith('event-')) {
        endpoint = '/client/event/scan-qr';
        id = scannedData.text.replace('event-', '');
      } else {
        throw new Error('Invalid QR code');
      }

      const response = await fetch(`${APIBase}${endpoint}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id }),
      });

      const result = await response.json();

      if (response.ok) {
        Swal.fire({
          title: 'Success!',
          text: 'You earned 10 points!',
          icon: 'success',
          timer: 2000,
        }).then(() => navigate('/app/profile'));
      } else {
        switch (result.code) {
          case 'ALREADY_CHECKED_IN':
            Swal.fire({
              title: t.alreadyCheckedIn,
              text: t.alreadyCheckedIn,
              icon: 'info',
              timer: 2000,
            });
            break;

          case 'VENUE_NOT_FOUND':
            Swal.fire({
              title: t.error,
              text: t.venueNotFound,
              icon: 'error',
              timer: 3000,
            });
            break;

          case 'USER_NOT_FOUND':
            Swal.fire({
              title: t.error,
              text: t.userNotFound,
              icon: 'error',
              timer: 3000,
            });
            break;

          default:
            Swal.fire({
              title: t.error,
              text: result.message || t.genericError,
              icon: 'error',
              timer: 3000,
            });
        }
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: error.message || t.genericError,
        icon: 'error',
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleError = (err) => {
    setError(t.cameraError);
    setCameraError(true);
    console.error(err);
  };

  const handleReScan = () => {
    setScannedData(null);
    setReScan(false);
  };

  const previewStyle = {
    height: 320,
    width: 320,
    borderRadius: '10px',
    overflow: 'hidden',
    border: '2px solid #86ff0d',
  };

  return (
    <div className="flex flex-col items-center justify-start h-screen bg-gray-900 px-4 pt-28 text-white">
      {/* Return to Home Button */}
      <button
        className="absolute top-22 left-4 bg-gray-800 text-white p-3 rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out"
        onClick={() => navigate('/app/home')}
      >
        <FaArrowLeft className="text-xl" />
      </button>

      {loading ? (
        <div className="flex flex-col items-center justify-center">
          <StageSpinner size={60} color="#86ff0d" />
          <p className="text-white mt-4">{t.processing}</p>
        </div>
      ) : (
        <>
          <h2 className="text-2xl text-white mb-4 mt-3">{t.title}</h2>
          <p className="text-center text-gray-400 mb-6">{t.instruction}</p>
          {scannedData ? (
            <>
              <h3 className="text-xl mb-4">{t.detectedVenue}</h3>
              <button
                className="mt-6 px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600"
                onClick={processScan}
              >
                {t.claimPoints}
              </button>
              <button
                className="mt-6 px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600 ml-4"
                onClick={handleReScan}
              >
                {t.rescan}
              </button>
            </>
          ) : (
            <>
              {!cameraError && !reScan ? (
                <div className="relative w-full h-56">
                  <QrReader
                    delay={300}
                    constraints={{ facingMode: 'environment' }}
                    style={previewStyle}
                    onError={handleError}
                    onResult={(result) => {
                      if (result) {
                        setScannedData(result);
                      }
                    }}
                  />
                </div>
              ) : (
                <p className="text-red-500">{t.cameraError}</p>
              )}
            </>
          )}
          {error && <p className="text-red-500 mt-4">{error}</p>}
        </>
      )}
      <LowerBar />
    </div>
  );
};

export default QRScan;
