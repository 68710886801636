import React, { createContext, ReactNode, useContext, useState } from 'react';

type LanguageOption = {
  value: string;
  label: string;
  flag: string;
};

const languageOptions: LanguageOption[] = [
  { value: 'english', label: '🇬🇧 English', flag: '🇬🇧' },
  { value: 'spanish', label: '🇪🇸 Español ', flag: '🇪🇸' },
  { value: 'polish', label: '🇵🇱 Polski', flag: '🇵🇱' },
];

interface LanguageContextProps {
  language: string;
  setLanguage: (language: string) => void;
  options: LanguageOption[];
  translations: Record<string, Record<string, string>>;
}

const translations = {
  english: {
    verified: 'Verified',
    network: 'Taki Network',
    date: 'Date',
    time: 'Time',
    venue: 'Venue',
    address: 'Address',
    genres: 'Genres',
    venueType: 'Venue Type',
    dressCode: 'Dress Code',
    ageRestriction: 'Age Restriction',
    djs: 'DJs',
    viewOnFacebook: 'View on Facebook',
    showMore: 'Show more',
    showLess: 'Show less',
    hideAdvancedFilters: 'Hide Advanced Filters',
    showAdvancedFilters: 'Show Advanced Filters',
    hideMap: 'Hide Map',
    showMap: 'Show Map',
    hideClubFilter: 'Hide Club Filter',
    showClubFilter: 'Show Club Filter',
    clearAllFilters: 'Clear',
    search: 'Apply',
    from: 'From',
    to: 'To',
    applyFilters: 'Apply Filters',
    cancel: 'Cancel',
    apply: 'Apply',
    party: 'Party',
    map: 'Map',
    karaoke: 'Karaoke',
  },
  spanish: {
    verified: 'Verificado',
    network: 'Red Taki',
    date: 'Fecha',
    time: 'Hora',
    venue: 'Lugar',
    address: 'Dirección',
    genres: 'Géneros',
    venueType: 'Tipo de Lugar',
    dressCode: 'Código de Vestimenta',
    ageRestriction: 'Restricción de Edad',
    djs: 'DJs',
    viewOnFacebook: 'Ver en Facebook',
    showMore: 'Mostrar más',
    showLess: 'Mostrar menos',
    hideAdvancedFilters: 'Ocultar filtros avanzados',
    showAdvancedFilters: 'Mostrar filtros avanzados',
    hideMap: 'Ocultar mapa',
    showMap: 'Mostrar mapa',
    hideClubFilter: 'Ocultar filtro de club',
    showClubFilter: 'Mostrar filtro de club',
    clearAllFilters: 'Borrar',
    search: 'Buscar',
    from: 'Desde',
    to: 'Hasta',
    applyFilters: 'Aplicar filtros',
    cancel: 'Cancelar',
    apply: 'Aplicar',
    party: 'Fiestas',
    map: 'Mapa',
    karaoke: 'Karaokes',
  },
  polish: {
    verified: 'Zweryfikowane',
    network: 'Sieć Taki',
    date: 'Data',
    time: 'Czas',
    venue: 'Miejsce',
    address: 'Adres',
    genres: 'Gatunki',
    venueType: 'Typ Miejsca',
    dressCode: 'Dress Code',
    ageRestriction: 'Ograniczenie wiekowe',
    djs: 'DJe',
    viewOnFacebook: 'Zobacz na Facebooku',
    showMore: 'Pokaż więcej',
    showLess: 'Pokaż mniej',
    hideAdvancedFilters: 'Ukryj zaawansowane filtry',
    showAdvancedFilters: 'Pokaż zaawansowane filtry',
    hideMap: 'Ukryj mapę',
    showMap: 'Pokaż mapę',
    hideClubFilter: 'Ukryj filtr klubu',
    showClubFilter: 'Pokaż filtr klubu',
    clearAllFilters: 'Wyczyść',
    search: 'Szukaj',
    from: 'Od',
    to: 'Do',
    applyFilters: 'Zastosuj filtry',
    cancel: 'Anuluj',
    apply: 'Zastosuj',
    party: 'Impreza',
    map: 'Mapa',
    karaoke: 'Karaoke',
  },
};

const LanguageContext = createContext<LanguageContextProps>({
  language: 'english',
  setLanguage: () => {},
  options: languageOptions,
  translations: {},
});

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState('english');

  return (
    <LanguageContext.Provider value={{ language, setLanguage, options: languageOptions, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};
