import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaChevronLeft, FaPlus, FaCog, FaTrash, FaSave, FaCheck } from 'react-icons/fa';
import Modal from 'react-modal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const translations = {
  english: {
    roomDetails: 'Room Details',
    name: 'Name',
    capacity: 'Capacity',
    venue: 'Venue',
    createdBy: 'Created By',
    playlists: 'Playlists',
    createPlaylist: 'Create Playlist',
    updatePlaylist: 'Update Playlist',
    deletePlaylist: 'Delete Playlist',
    deleteConfirmation: 'Are you sure you want to delete this playlist?',
    deleteSuccess: 'Playlist deleted successfully!',
    deleteFailure: 'Failed to delete playlist. Please try again later.',
    updateSuccess: 'Playlist updated successfully!',
    updateFailure: 'Failed to update playlist. Please try again later.',
    playlistName: 'Playlist Name',
    back: 'Back to Venue',
    fetchError: 'Failed to fetch room details. Please try again later.',
    noPlaylists: 'No playlists available for this room.',
    loading: 'Loading...',
    cancel: 'Cancel',
    create: 'Create',
    save: 'Save',
    playlistSuccess: 'Playlist created successfully!',
    playlistFailure: 'Failed to create playlist. Please try again later.',
  },
  spanish: {
    roomDetails: 'Detalles de la Sala',
    name: 'Nombre',
    capacity: 'Capacidad',
    venue: 'Lugar',
    createdBy: 'Creado Por',
    playlists: 'Listas de Reproducción',
    createPlaylist: 'Crear Lista de Reproducción',
    updatePlaylist: 'Actualizar Lista de Reproducción',
    deletePlaylist: 'Eliminar Lista de Reproducción',
    deleteConfirmation: '¿Estás seguro de que deseas eliminar esta lista de reproducción?',
    deleteSuccess: '¡Lista de reproducción eliminada con éxito!',
    deleteFailure: 'Error al eliminar la lista de reproducción. Por favor, inténtelo de nuevo más tarde.',
    updateSuccess: '¡Lista de reproducción actualizada con éxito!',
    updateFailure: 'Error al actualizar la lista de reproducción. Por favor, inténtelo de nuevo más tarde.',
    playlistName: 'Nombre de la Lista de Reproducción',
    back: 'Volver al Lugar',
    fetchError: 'Error al obtener los detalles de la sala. Por favor, inténtelo de nuevo más tarde.',
    noPlaylists: 'No hay listas de reproducción disponibles para esta sala.',
    loading: 'Cargando...',
    cancel: 'Cancelar',
    create: 'Crear',
    save: 'Guardar',
    playlistSuccess: '¡Lista de reproducción creada con éxito!',
    playlistFailure: 'Error al crear la lista de reproducción. Por favor, inténtelo de nuevo más tarde.',
  },
  polish: {
    roomDetails: 'Szczegóły Pokoju',
    name: 'Nazwa',
    capacity: 'Pojemność',
    venue: 'Miejsce',
    createdBy: 'Utworzone Przez',
    playlists: 'Listy Odtwarzania',
    createPlaylist: 'Utwórz Listę Odtwarzania',
    updatePlaylist: 'Aktualizuj Listę Odtwarzania',
    deletePlaylist: 'Usuń Listę Odtwarzania',
    deleteConfirmation: 'Czy na pewno chcesz usunąć tę listę odtwarzania?',
    deleteSuccess: 'Lista odtwarzania została pomyślnie usunięta!',
    deleteFailure: 'Nie udało się usunąć listy odtwarzania. Spróbuj ponownie później.',
    updateSuccess: 'Lista odtwarzania została pomyślnie zaktualizowana!',
    updateFailure: 'Nie udało się zaktualizować listy odtwarzania. Spróbuj ponownie później.',
    playlistName: 'Nazwa Listy Odtwarzania',
    back: 'Powrót do Miejsca',
    fetchError: 'Nie udało się pobrać szczegółów pokoju. Spróbuj ponownie później.',
    noPlaylists: 'Brak dostępnych list odtwarzania dla tego pokoju.',
    loading: 'Ładowanie...',
    cancel: 'Anuluj',
    create: 'Utwórz',
    save: 'Zapisz',
    playlistSuccess: 'Lista odtwarzania została pomyślnie utworzona!',
    playlistFailure: 'Nie udało się utworzyć listy odtwarzania. Spróbuj ponownie później.',
  },
};

const RoomDetail: React.FC = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const [roomData, setRoomData] = useState<any>(null);
  const [playlists, setPlaylists] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState<boolean>(false);
  const [newPlaylistName, setNewPlaylistName] = useState<string>('');
  const [selectedPlaylist, setSelectedPlaylist] = useState<any>(null);
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchRoom();
    fetchPlaylists();
  }, [roomId]);

  const fetchRoom = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await fetch(`${APIBase}/client/room/${roomId}`, data);
      if (response.ok) {
        const result = await response.json();
        setRoomData(result.room);
      } else {
        throw new Error(t.fetchError);
      }
    } catch {
      Swal.fire({
        title: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchPlaylists = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await fetch(`${APIBase}/client/playlist/byRoomId/${roomId}`, data);
      if (response.ok) {
        const result = await response.json();
        setPlaylists(result.playlists || []);
      } else {
        throw new Error(t.fetchError);
      }
    } catch {
      Swal.fire({
        title: t.fetchError,
        text: t.noPlaylists,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const handleCreatePlaylist = async () => {
    const data: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ roomId, name: newPlaylistName }),
    };
    try {
      const response = await fetch(`${APIBase}/client/playlist`, data);
      if (response.ok) {
        Swal.fire({
          title: t.playlistSuccess,
          icon: 'success',
          timer: 2000,
        });
        fetchPlaylists();
        setIsCreateModalOpen(false);
        setNewPlaylistName('');
      } else {
        throw new Error(t.playlistFailure);
      }
    } catch {
      Swal.fire({
        text: t.playlistFailure,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const handleDeletePlaylist = async (playlistId: string) => {
    const confirm = await Swal.fire({
      title: t.deletePlaylist,
      text: t.deleteConfirmation,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: t.deletePlaylist,
    });

    if (!confirm.isConfirmed) return;

    const data: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/playlist/${playlistId}`, data);
      if (response.ok) {
        Swal.fire({
          title: t.deleteSuccess,
          icon: 'success',
          timer: 2000,
        });
        fetchPlaylists();
      } else {
        throw new Error(t.deleteFailure);
      }
    } catch {
      Swal.fire({
        title: t.deleteFailure,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const openUpdateModal = (playlist: any) => {
    setSelectedPlaylist(playlist);
    setIsUpdateModalOpen(true);
  };

  const handleUpdatePlaylist = async () => {
    if (!selectedPlaylist) return;

    const data: RequestInit = {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: selectedPlaylist.name,
        type: selectedPlaylist.type, // Include the type of the playlist
      }),
    };

    try {
      const response = await fetch(`${APIBase}/client/playlist/${selectedPlaylist._id}`, data);
      if (response.ok) {
        Swal.fire({
          title: t.updateSuccess,
          icon: 'success',
          timer: 2000,
        });
        fetchPlaylists();
        setIsUpdateModalOpen(false);
      } else {
        throw new Error(t.updateFailure);
      }
    } catch {
      Swal.fire({
        title: t.updateFailure,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  if (loading) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <p>{t.loading}</p>
      </div>
    );
  }

  if (!roomData) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <p>{t.fetchError}</p>
      </div>
    );
  }

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="fixed top-20 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out"
      >
        <FaChevronLeft className="text-xl" />
      </button>
      <div className="max-w-4xl mx-auto mt-12">
        <h2 className="text-4xl font-extrabold tracking-tight mb-8">{t.roomDetails}</h2>
        <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
          <p className="text-lg mb-4">
            <strong>{t.name}:</strong> {roomData.name}
          </p>
          <p className="text-lg mb-4">
            <strong>{t.capacity}:</strong> {roomData.capacity}
          </p>
          <p className="text-lg mb-4">
            <strong>{t.venue}:</strong> {roomData.venueId?.name || 'N/A'}
          </p>
          <p className="text-lg mb-4">
            <strong>{t.createdBy}:</strong> {roomData.createdBy?.name || 'N/A'}
          </p>
          {roomData.imageURL && (
            <img src={roomData.imageURL} alt={roomData.name} className="w-full h-72 object-cover rounded-md mb-6" />
          )}
        </div>
        <div className="mt-8">
          <h3 className="text-2xl font-semibold mb-4">{t.playlists}</h3>
          <button
            onClick={() => setIsCreateModalOpen(true)}
            className="flex items-center bg-gradient-to-r from-green-500 to-green-700 text-white px-4 py-2 rounded-full shadow-lg hover:from-green-600 hover:to-green-800 transition-all duration-300 ease-in-out mb-4"
          >
            <FaPlus className="mr-2" /> {t.createPlaylist}
          </button>
          <ul className="space-y-2">
            {playlists.length > 0 ? (
              [...playlists].reverse()?.map((playlist) => (
                <li key={playlist._id} className="flex items-center justify-between bg-gray-700 p-4 rounded-lg">
                  <div>
                    <Link to={`/app/admin/view-playlist/${playlist._id}`} className="text-white hover:underline">
                      {playlist.name} ({playlist.type})
                    </Link>
                  </div>
                  <div className="flex items-center space-x-4">
                    <button
                      onClick={() => openUpdateModal(playlist)}
                      className="p-2 bg-blue-600 text-white rounded-full hover:bg-blue-500 transition flex items-center justify-center"
                      title="Edit Playlist"
                    >
                      <FaCog className="text-xl" />
                    </button>
                    <button
                      onClick={() => handleDeletePlaylist(playlist._id)}
                      className="p-2 bg-red-600 text-white rounded-full hover:bg-red-500 transition flex items-center justify-center"
                      title="Delete Playlist"
                    >
                      <FaTrash className="text-xl" />
                    </button>
                  </div>
                </li>
              ))
            ) : (
              <p className="text-gray-400">{t.noPlaylists}</p>
            )}
          </ul>
        </div>
      </div>
      <Modal
        isOpen={isCreateModalOpen}
        onRequestClose={() => setIsCreateModalOpen(false)}
        contentLabel="Create Playlist Modal"
        className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20 text-white"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-2xl font-semibold mb-4">{t.createPlaylist}</h2>
        <div className="space-y-4">
          <label className="block text-sm font-medium mb-1">{t.playlistName}</label>
          <input
            type="text"
            className="w-full p-2 rounded-md bg-gray-700 text-white"
            value={newPlaylistName}
            onChange={(e) => setNewPlaylistName(e.target.value)}
          />
        </div>
        <div className="flex justify-end mt-6 space-x-2">
          <button
            onClick={() => setIsCreateModalOpen(false)}
            className="px-4 py-2 bg-gray-600 rounded-md hover:bg-gray-500 transition"
          >
            {t.cancel}
          </button>
          <button
            onClick={handleCreatePlaylist}
            className="px-4 py-2 bg-green-600 rounded-md hover:bg-green-500 transition"
          >
            {t.create}
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={isUpdateModalOpen}
        onRequestClose={() => setIsUpdateModalOpen(false)}
        contentLabel="Update Playlist Modal"
        className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20 text-white"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-2xl font-semibold mb-4">{t.updatePlaylist}</h2>
        <div className="space-y-4">
          <label className="block text-sm font-medium mb-1">{t.playlistName}</label>
          <input
            type="text"
            className="w-full p-2 rounded-md bg-gray-700 text-white"
            value={selectedPlaylist?.name || ''}
            onChange={(e) => setSelectedPlaylist((prev: any) => ({ ...prev, name: e.target.value }))}
          />
          <label className="block text-sm font-medium mb-1">Playlist Type</label>
          <select
            className="w-full p-2 rounded-md bg-gray-700 text-white"
            value={selectedPlaylist?.type || ''}
            onChange={(e) => setSelectedPlaylist((prev: any) => ({ ...prev, type: e.target.value }))}
          >
            <option value="regular">Regular</option>
            <option value="contest">Contest</option>
            <option value="other">Other</option>
          </select>
        </div>
        <div className="flex justify-end mt-6 space-x-2">
          <button
            onClick={handleUpdatePlaylist}
            className="p-2 bg-green-600 rounded-full hover:bg-green-500 transition"
            title={t.save}
          >
            <FaCheck className="text-lg" />
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default RoomDetail;
