import {
  faGlassCheers,
  faHeart,
  faLaptopCode,
  faMap,
  faMicrophone,
  faMusic,
  faPercentage,
  faCoins,
  faUserFriends,
  faVoteYea,
  faSignInAlt,
} from '@fortawesome/free-solid-svg-icons'; // Added new icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLanguage } from 'Contexts/LanguageContext';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import LowerBar from '../../PartyTicket/LowerBar';

const HomeScreen: React.FC = () => {
  const navigate = useNavigate();
  const { language } = useLanguage();

  const translations = {
    english: {
      title: 'Pick your vibe! 🎉',
      party: 'Party',
      karaoke: 'Karaoke',
      djBattle: 'DJ Battle',
      checkIn: 'Check-In',
      earnCoins: 'Earn Coins',
      discounts: 'Deals',
      djRequest: 'DJ Request (Soon)',
      composeDrink: 'Custom Drink (Soon)',
      match: 'Match (Soon)',
      dedication: 'Dedications (Soon)',
      vote: 'Vote (Soon)',
      map: 'Map',
    },
    spanish: {
      title: '¡Elige tu onda! 🎉',
      party: 'Fiesta',
      karaoke: 'Karaoke',
      djBattle: 'Batalla DJ',
      checkIn: 'Check-In',
      earnCoins: 'Gana Monedas',
      discounts: 'Ofertas',
      djRequest: 'Pedido DJ (Pronto)',
      composeDrink: 'Bebida (Pronto)',
      match: 'Match (Pronto)',
      dedication: 'Dedicatorias (Pronto)',
      vote: 'Głosowanie (Pronto)',
      map: 'Mapa',
    },
    polish: {
      title: 'Wybierz klimat! 🎉',
      party: 'Impreza',
      karaoke: 'Karaoke',
      djBattle: 'Bitwa DJ',
      checkIn: 'Check-In',
      earnCoins: 'Zarabiaj Monety',
      discounts: 'Rabaty',
      djRequest: 'DJ (Wkrótce)',
      composeDrink: 'Drink (Wkrótce)',
      match: 'Poznaj (Wkrótce)',
      dedication: 'Dedykacje (Wkrótce)',
      vote: 'Głosowanie (Wkrótce)',
      map: 'Mapa',
    },
  };

  const t = translations[language as keyof typeof translations];

  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-black text-white flex flex-col items-center justify-center relative pt-24 md:pt-0 mb-24">
      {/* Spicy Message */}
      <h1 className="text-2xl sm:text-3xl text-center font-bold text-gray-200 mb-8 px-4">{t.title}</h1>

      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 w-full max-w-3xl mx-auto px-4">
        {/* Karaoke Button */}
        <button
          onClick={() => navigate('/app/partyticket/rooms/66cc50ad5d4ada8c64b24616')}
          className="flex flex-col items-center justify-center bg-gray-800 hover:bg-gray-700 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105"
        >
          <FontAwesomeIcon icon={faMicrophone} className="text-2xl sm:text-3xl text-gray-300 mb-2 sm:mb-3" />
          <span className="text-gray-300 text-sm sm:text-lg font-semibold">{t.karaoke}</span>
        </button>

        {/* DJ Battle Button */}
        <button
          onClick={() => navigate('/app/partyticket/dj-battles/66cc50ad5d4ada8c64b24616')}
          className="flex flex-col items-center justify-center bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105"
        >
          <FontAwesomeIcon icon={faVoteYea} className="text-2xl sm:text-3xl text-white mb-2 sm:mb-3" />
          <span className="text-white text-sm sm:text-lg font-semibold">{t.djBattle}</span>
        </button>

        {/* Party Button */}
        <button
          onClick={() => navigate('/app/partyticket/events')}
          className="flex flex-col items-center justify-center bg-gray-800 hover:bg-gray-700 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105"
        >
          <FontAwesomeIcon icon={faMusic} className="text-2xl sm:text-3xl text-gray-300 mb-2 sm:mb-3" />
          <span className="text-gray-300 text-sm sm:text-lg font-semibold">{t.party}</span>
        </button>

        {/* Check-In Button */}
        <button
          onClick={() => navigate('/app/check-in')}
          className="flex flex-col items-center justify-center bg-gray-800 hover:bg-gray-700 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105"
        >
          <FontAwesomeIcon icon={faSignInAlt} className="text-2xl sm:text-3xl text-gray-300 mb-2 sm:mb-3" />
          <span className="text-gray-300 text-sm sm:text-lg font-semibold">{t.checkIn}</span>
        </button>

        {/* Earn Coins Button */}
        <button
          onClick={() => navigate('/app/partyticket/earn-coins/66cc50ad5d4ada8c64b24616')}
          className="flex flex-col items-center justify-center bg-gray-800 hover:bg-gray-700 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105"
        >
          <FontAwesomeIcon icon={faCoins} className="text-2xl sm:text-3xl text-gray-300 mb-2 sm:mb-3" />
          <span className="text-gray-300 text-sm sm:text-lg font-semibold">{t.earnCoins}</span>
        </button>

        {/* Discounts Button */}
        <button
          onClick={() => navigate('/app/partyticket/discounts/66cc50ad5d4ada8c64b24616')}
          className="flex flex-col items-center justify-center bg-gray-800 hover:bg-gray-700 transition-all p-4 sm:p-6 rounded-lg shadow-md hover:scale-105"
        >
          <FontAwesomeIcon icon={faPercentage} className="text-2xl sm:text-3xl text-gray-300 mb-2 sm:mb-3" />
          <span className="text-gray-300 text-sm sm:text-lg font-semibold">{t.discounts}</span>
        </button>

        {/* Coming Soon Buttons */}
        <div className="flex flex-col items-center justify-center bg-gray-800 p-4 sm:p-6 rounded-lg shadow-md opacity-50 cursor-not-allowed">
          <FontAwesomeIcon icon={faLaptopCode} className="text-2xl sm:text-3xl text-gray-600 mb-2 sm:mb-3" />
          <span className="text-gray-600 text-sm sm:text-lg font-semibold">{t.djRequest}</span>
        </div>
        <div className="flex flex-col items-center justify-center bg-gray-800 p-4 sm:p-6 rounded-lg shadow-md opacity-50 cursor-not-allowed">
          <FontAwesomeIcon icon={faGlassCheers} className="text-2xl sm:text-3xl text-gray-600 mb-2 sm:mb-3" />
          <span className="text-gray-600 text-sm sm:text-lg font-semibold">{t.composeDrink}</span>
        </div>
        <div className="flex flex-col items-center justify-center bg-gray-800 p-4 sm:p-6 rounded-lg shadow-md opacity-50 cursor-not-allowed">
          <FontAwesomeIcon icon={faHeart} className="text-2xl sm:text-3xl text-gray-600 mb-2 sm:mb-3" />
          <span className="text-gray-600 text-sm sm:text-lg font-semibold">{t.match}</span>
        </div>
        <div className="flex flex-col items-center justify-center bg-gray-800 p-4 sm:p-6 rounded-lg shadow-md opacity-50 cursor-not-allowed">
          <FontAwesomeIcon icon={faUserFriends} className="text-2xl sm:text-3xl text-gray-600 mb-2 sm:mb-3" />
          <span className="text-gray-600 text-sm sm:text-lg font-semibold">{t.dedication}</span>
        </div>
        <div className="flex flex-col items-center justify-center bg-gray-800 p-4 sm:p-6 rounded-lg shadow-md opacity-50 cursor-not-allowed">
          <FontAwesomeIcon icon={faMap} className="text-2xl sm:text-3xl text-gray-600 mb-2 sm:mb-3" />
          <span className="text-gray-600 text-sm sm:text-lg font-semibold">{t.map}</span>
        </div>
      </div>

      <LowerBar />
    </div>
  );
};

export default HomeScreen;
