import categorizeEvents from 'helpers/categorizeEvents'; // Adjust the import path as necessary
import React from 'react';
import EventCard from './EventCard';
import { Event } from 'types';

interface EventListProps {
  events: Event[];
  singleList: boolean;
}

const EventList: React.FC<EventListProps> = ({ events, singleList }) => {
  const timeZone = 'Europe/Warsaw'; // Time zone for Katowice, Poland

  // Sort events by date first
  const sortedEvents = events.slice().sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

  const { happeningNow, today, tomorrow, thisWeek, thisWeekend, nextWeek, nextWeekend, upcoming } = categorizeEvents(
    sortedEvents,
    timeZone,
  );

  const renderEventSection = (title: string, events: Event[]) =>
    !!events.length && (
      <div className="mb-6">
        <h2 className="text-2xl font-bold mb-2 text-white pt-3">{title}</h2>
        <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4`}>
          {events.map((event, index) => (
            <EventCard
              key={index}
              title={event.title}
              date={event.date}
              time={event.time}
              location={event.clubId?.name} // Location name from the venue
              address={event.clubId?.address} // Address from the venue
              imageURL={event.imageURL || ''}
              facebookLink={event.facebookLink || ''}
              genres={event.genres}
              djs={event.djs}
              description={event.description}
              isVerified={event.verified}
              isTakiNetwork={event.network}
              startHour={event.startHour}
              endHour={event.endHour}
              eventId={event._id} // Changed to _id
            />
          ))}
        </div>
      </div>
    );

  return (
    <div className="w-full px-3">
      {renderEventSection('Happening Now', happeningNow)}
      {renderEventSection('Today', today)}
      {renderEventSection('Tomorrow', tomorrow)}
      {renderEventSection('This Week', thisWeek)}
      {renderEventSection('This Weekend', thisWeekend)}
      {renderEventSection('Next Week', nextWeek)}
      {renderEventSection('Next Weekend', nextWeekend)}
      {renderEventSection('Upcoming', upcoming)}
    </div>
  );
};

export default EventList;
