import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import { FaChevronLeft, FaEdit, FaTimes } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { ActivityData } from 'types'; // Ensure you're importing ActivityData

const EventEditor: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>();
  const [eventData, setEventData] = useState({
    title: '',
    description: '',
    date: '',
    time: '',
    startHour: '',
    endHour: '',
    imageURL: '',
    facebookLink: '',
    ticketLink: '', // Added ticketLink
    genres: [] as string[],
    dressCode: '',
    ageRestriction: '',
    djs: [] as string[],
    verified: false,
    network: false,
    clubId: '',
    type: '',
  });
  const [venues, setVenues] = useState([]);
  const [genreInput, setGenreInput] = useState('');
  const [djInput, setDjInput] = useState('');
  const [imageFile, setImageFile] = useState<File | null>(null);
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [activities, setActivities] = useState<ActivityData[]>([]); // Holds event activities
  const [newActivity, setNewActivity] = useState<ActivityData>({ name: '', points: 0 }); // New activity state
  const [selectedActivity, setSelectedActivity] = useState<ActivityData | null>(null); // For editing activity
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Modal for editing
  const translations = {
    english: {
      back: 'Back',
      editEvent: 'Edit Event',
      title: 'Title',
      description: 'Description',
      date: 'Date',
      time: 'Time',
      startHour: 'Start Hour',
      endHour: 'End Hour',
      imageURL: 'Image URL',
      uploadImage: 'Upload Image',
      facebookLink: 'Facebook Link',
      ticketLink: 'Ticket Link',
      genres: 'Genres',
      add: 'Add',
      dressCode: 'Dress Code',
      selectDressCode: 'Select Dress Code',
      noDressCode: 'No Dress Code',
      casual: 'Casual',
      smartCasual: 'Smart Casual',
      business: 'Business Attire',
      formal: 'Formal',
      blackTie: 'Black Tie',
      cocktail: 'Cocktail Attire',
      costume: 'Costume',
      beach: 'Beachwear',
      festival: 'Festival',
      themed: 'Themed', // Added
      ageRestriction: 'Age Restriction',
      allAges: 'All Ages',
      verified: 'Verified',
      network: 'Network',
      updateEvent: 'Update Event',
      activities: 'Activities',
      activityName: 'Activity Name',
      activityPoints: 'Activity Points',
      addActivity: 'Add Activity',
      editActivity: 'Edit Activity',
      deleteActivity: 'Delete Activity',
      saveActivity: 'Save Activity',
      success: 'Success',
      updateSuccess: 'Event updated successfully! 🎉',
      activitySuccess: 'Activity updated successfully!',
      deleteSuccess: 'Activity deleted successfully!',
      error: 'Error',
      fetchError: 'Failed to fetch event. Please try again later.',
      updateError: 'Failed to update event. Please try again later.',
      uploadError: 'Image upload failed.',
      party: 'Party',
      karaoke: 'Karaoke',
      selectClub: 'Select a club...',
      selectType: 'Select event type...',
      djs: 'DJs', // Added
      type: 'Type', // Added
      cancel: 'Cancel',
    },
    spanish: {
      back: 'Atrás',
      editEvent: 'Editar Evento',
      title: 'Título',
      description: 'Descripción',
      date: 'Fecha',
      time: 'Hora',
      startHour: 'Hora de Inicio',
      endHour: 'Hora de Fin',
      imageURL: 'URL de la Imagen',
      uploadImage: 'Subir Imagen',
      facebookLink: 'Enlace de Facebook',
      ticketLink: 'Enlace de Tickets',
      genres: 'Géneros',
      add: 'Añadir',
      dressCode: 'Código de Vestimenta',
      selectDressCode: 'Seleccionar Código de Vestimenta',
      noDressCode: 'Sin Código de Vestimenta',
      casual: 'Informal',
      smartCasual: 'Smart Casual',
      business: 'Atuendo de Negocios',
      formal: 'Formal',
      blackTie: 'Etiqueta',
      cocktail: 'Etiqueta Coctel',
      costume: 'Disfraz',
      beach: 'Ropa de Playa',
      festival: 'Ropa de Festival',
      themed: 'Temático', // Added
      ageRestriction: 'Restricción de Edad',
      allAges: 'Todas las Edades',
      verified: 'Verificado',
      network: 'Red',
      updateEvent: 'Actualizar Evento',
      activities: 'Actividades',
      activityName: 'Nombre de la Actividad',
      activityPoints: 'Puntos de la Actividad',
      addActivity: 'Añadir Actividad',
      editActivity: 'Editar Actividad',
      deleteActivity: 'Eliminar Actividad',
      saveActivity: 'Guardar Actividad',
      success: 'Éxito',
      updateSuccess: '¡Evento actualizado con éxito! 🎉',
      activitySuccess: '¡Actividad actualizada con éxito!',
      deleteSuccess: '¡Actividad eliminada con éxito!',
      error: 'Error',
      fetchError: 'No se pudo obtener el evento. Por favor, inténtelo de nuevo más tarde.',
      updateError: 'No se pudo actualizar el evento. Por favor, inténtelo de nuevo más tarde.',
      uploadError: 'La carga de la imagen falló.',
      party: 'Fiesta',
      karaoke: 'Karaoke',
      selectClub: 'Selecciona un club...',
      selectType: 'Seleccionar tipo de evento...',
      djs: 'DJs', // Added
      type: 'Tipo', // Added
      cancel: 'Cancelar',
    },
    polish: {
      back: 'Powrót',
      editEvent: 'Edytuj Wydarzenie',
      title: 'Tytuł',
      description: 'Opis',
      date: 'Data',
      time: 'Godzina',
      startHour: 'Godzina Rozpoczęcia',
      endHour: 'Godzina Zakończenia',
      imageURL: 'URL Obrazu',
      uploadImage: 'Prześlij Obraz',
      facebookLink: 'Link do Facebooka',
      ticketLink: 'Link do Biletów',
      genres: 'Gatunki',
      add: 'Dodaj',
      dressCode: 'Kod Ubioru',
      selectDressCode: 'Wybierz Kod Ubioru',
      noDressCode: 'Brak Kodu Ubioru',
      casual: 'Codzienny',
      smartCasual: 'Smart Casual',
      business: 'Strój Biznesowy',
      formal: 'Formalny',
      blackTie: 'Strój Wieczorowy',
      cocktail: 'Strój Koktajlowy',
      costume: 'Kostium',
      beach: 'Strój Plażowy',
      festival: 'Strój Festiwalowy',
      themed: 'Tematyczny', // Added
      ageRestriction: 'Ograniczenie Wiekowe',
      allAges: 'Wszystkie Wiekowe',
      verified: 'Zweryfikowane',
      network: 'Sieć',
      updateEvent: 'Zaktualizuj Wydarzenie',
      activities: 'Zajęcia',
      activityName: 'Nazwa Zajęcia',
      activityPoints: 'Punkty za Zajęcie',
      addActivity: 'Dodaj Zajęcie',
      editActivity: 'Edytuj Zajęcie',
      deleteActivity: 'Usuń Zajęcie',
      saveActivity: 'Zapisz Zajęcie',
      success: 'Sukces',
      updateSuccess: 'Wydarzenie zostało zaktualizowane pomyślnie! 🎉',
      activitySuccess: 'Zajęcie zostało pomyślnie zaktualizowane!',
      deleteSuccess: 'Zajęcie zostało pomyślnie usunięte!',
      error: 'Błąd',
      fetchError: 'Nie udało się pobrać wydarzenia. Spróbuj ponownie później.',
      updateError: 'Nie udało się zaktualizować wydarzenia. Spróbuj ponownie później.',
      uploadError: 'Przesyłanie obrazu nie powiodło się.',
      party: 'Impreza',
      karaoke: 'Karaoke',
      selectClub: 'Wybierz klub...',
      selectType: 'Wybierz typ wydarzenia...',
      djs: 'DJs', // Added
      type: 'Typ', // Added
      cancel: 'Anuluj',
    },
  };

  type Language = 'english' | 'spanish' | 'polish';

  const t = translations[language as Language];

  useEffect(() => {
    fetchVenues();
    if (eventId) {
      fetchEvent(eventId);
    }
  }, [currentToken, eventId]);

  const fetchVenues = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    await fetch(`${APIBase}/client/venue`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.fetchError);
        }
      })
      .then((responseJson) => {
        setVenues(responseJson);
      })
      .catch((error) => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const fetchEvent = async (eventId: string) => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    await fetch(`${APIBase}/client/event/byEventId/${eventId}`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(t.fetchError);
        }
      })
      .then((responseJson) => {
        setEventData({
          ...responseJson,
          date: new Date(responseJson.date).toISOString().split('T')[0],
          clubId: responseJson.clubId?._id,
          type: responseJson.type[0],
        });
        fetchActivities(); // Fetch activities after fetching event
      })
      .catch((error) => {
        Swal.fire({
          title: t.error,
          text: t.fetchError,
          showConfirmButton: false,
          timer: 3000,
        });
      });
  };

  const fetchActivities = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    try {
      const response = await fetch(`${APIBase}/client/activity/event/${eventId}`, data); // Adjust the URL as necessary
      if (!response.ok) throw new Error(t.fetchError);
      const result = await response.json();
      setActivities(result.activities); // Set fetched activities
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.fetchError + ' (Activities)',
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const checked = type === 'checkbox' ? (e.target as HTMLInputElement).checked : undefined;
    setEventData({ ...eventData, [name]: type === 'checkbox' ? checked : value });
  };

  const handleSelectChange = (selectedOption: any, name: string) => {
    setEventData({ ...eventData, [name]: selectedOption ? selectedOption.value : '' });
  };

  const handleGenreAdd = () => {
    if (genreInput && !eventData.genres.includes(genreInput)) {
      setEventData({ ...eventData, genres: [...eventData.genres, genreInput] });
      setGenreInput('');
    }
  };

  const handleGenreRemove = (genre: string) => {
    setEventData({ ...eventData, genres: eventData.genres.filter((g) => g !== genre) });
  };

  const handleDjAdd = () => {
    if (djInput && !eventData.djs.includes(djInput)) {
      setEventData({ ...eventData, djs: [...eventData.djs, djInput] });
      setDjInput('');
    }
  };

  const handleDjRemove = (dj: string) => {
    setEventData({ ...eventData, djs: eventData.djs.filter((d) => d !== dj) });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    let uploadedImageUrl = eventData.imageURL;

    if (imageFile) {
      const formData = new FormData();
      formData.append('mediaUpload', imageFile);

      const uploadResponse = await fetch(`${APIBase}/client/event/upload-media`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
        },
        body: formData,
      });

      if (uploadResponse.ok) {
        const uploadResult = await uploadResponse.json();
        uploadedImageUrl = uploadResult.imageURL;
      } else {
        Swal.fire({
          title: t.error,
          text: t.uploadError,
          icon: 'error',
          timer: 3000,
        });
        return;
      }
    }

    const data: RequestInit = {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ _id: eventId, ...eventData, imageURL: uploadedImageUrl }),
    };

    await fetch(`${APIBase}/client/event`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.success,
            text: t.updateSuccess,
            icon: 'success',
            timer: 2000,
          });
          navigate('/app/admin/events');
        } else {
          Swal.fire({
            title: t.error,
            text: t.updateError,
            icon: 'error',
            timer: 3000,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.updateError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const venueOptions = venues.map((venue: any) => ({
    value: venue._id,
    label: venue.name,
  }));

  const typeOptions = [
    { value: 'party', label: t.party },
    { value: 'karaoke', label: t.karaoke },
  ];

  const dressCodes = [
    { value: '', label: t.selectDressCode },
    { value: t.noDressCode, label: t.noDressCode },
    { value: t.casual, label: t.casual },
    { value: t.smartCasual, label: t.smartCasual },
    { value: t.business, label: t.business },
    { value: t.formal, label: t.formal },
    { value: t.blackTie, label: t.blackTie },
    { value: t.cocktail, label: t.cocktail },
    { value: t.themed, label: t.themed },
    { value: t.costume, label: t.costume },
    { value: t.beach, label: t.beach },
    { value: t.festival, label: t.festival },
  ];

  const genreOptions = [
    'Reggaeton',
    'Twerk',
    'Salsa',
    'Bachata',
    'Kizomba',
    'Merengue',
    'Cumbia',
    'Trap',
    'Rap',
    'House',
    'EDM',
    'Pop',
    'Rock',
    'Hip-hop',
    'Jazz',
    'Classical',
    'Country',
    'Reggae',
    'Blues',
    'Soul',
    'Funk',
    'Disco',
    'Techno',
    'Dubstep',
    'Trance',
    'Drum & Bass',
    'Garage',
    'Latin',
    'Ska',
    'Metal',
    'Punk',
    'R&B',
    'Folk',
    'Gospel',
    'Opera',
    'World',
    'K-Pop',
    'J-Pop',
  ];

  const handleActivitySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const data: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...newActivity, eventId }),
    };
    await fetch(`${APIBase}/client/activity`, data)
      .then((response) => response.json())
      .then(() => {
        setNewActivity({ name: '', points: 0 });
        fetchActivities(); // Refresh activities
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.updateError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const handleActivityDelete = async (activityId: string) => {
    const data: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${currentToken}`,
      },
    };
    await fetch(`${APIBase}/client/activity/${activityId}`, data)
      .then(() => {
        fetchActivities();
      }) // Refresh activities after deletion
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.updateError,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const openEditModal = (activity: any) => {
    setSelectedActivity(activity);
    setIsEditModalOpen(true);
  };

  const handleActivityEditSubmit = async () => {
    if (selectedActivity) {
      const data: RequestInit = {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(selectedActivity),
      };
      await fetch(`${APIBase}/client/activity/${selectedActivity._id}`, data)
        .then(() => {
          setIsEditModalOpen(false);
          fetchActivities(); // Refresh activities after edit
        })
        .catch(() => {
          Swal.fire({
            title: t.error,
            text: t.updateError,
            icon: 'error',
            timer: 3000,
          });
        });
    }
  };

  return (
    <div className="text-white min-h-screen flex flex-col items-center justify-center bg-gray-900 p-4 pt-20 pb-28">
      <button
        onClick={() => navigate(-1)}
        className="self-start mb-4 px-4 py-2 bg-gray-700 text-white rounded flex items-center"
      >
        <FaChevronLeft className="mr-2" /> {t.back}
      </button>
      <form className="w-full max-w-lg" onSubmit={handleSubmit}>
        <h2 className="text-2xl mb-4">{t.editEvent}</h2>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="title">
            {t.title}
          </label>
          <input
            type="text"
            id="title"
            name="title"
            value={eventData.title}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="description">
            {t.description}
          </label>
          <textarea
            id="description"
            name="description"
            value={eventData.description}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          ></textarea>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="date">
            {t.date}
          </label>
          <input
            type="date"
            id="date"
            name="date"
            value={eventData.date}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="time">
            {t.time}
          </label>
          <input
            type="time"
            id="time"
            name="time"
            value={eventData.time}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="startHour">
            {t.startHour}
          </label>
          <input
            type="time"
            id="startHour"
            name="startHour"
            value={eventData.startHour}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="endHour">
            {t.endHour}
          </label>
          <input
            type="time"
            id="endHour"
            name="endHour"
            value={eventData.endHour}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="imageURL">
            {t.imageURL}
          </label>
          <input
            type="text"
            id="imageURL"
            name="imageURL"
            value={eventData.imageURL}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="imageUpload">
            {t.uploadImage}
          </label>
          <input
            type="file"
            id="imageUpload"
            name="imageUpload"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files ? e.target.files[0] : null)}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="facebookLink">
            {t.facebookLink}
          </label>
          <input
            type="text"
            id="facebookLink"
            name="facebookLink"
            value={eventData.facebookLink}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="ticketLink">
            {t.ticketLink}
          </label>
          <input
            type="text"
            id="ticketLink"
            name="ticketLink"
            value={eventData.ticketLink} // Added ticketLink input
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="genres">
            {t.genres}
          </label>
          <div className="flex items-center">
            <input
              type="text"
              id="genres"
              name="genres"
              value={genreInput}
              onChange={(e) => setGenreInput(e.target.value)}
              className="w-full px-3 py-2 bg-gray-800 text-white rounded"
              onKeyDown={(e) => e.key === 'Enter' && (e.preventDefault(), handleGenreAdd())}
              list="genreOptions"
            />
            <datalist id="genreOptions">
              {genreOptions.map((genre) => (
                <option key={genre} value={genre} />
              ))}
            </datalist>
            <button
              type="button"
              onClick={handleGenreAdd}
              className="ml-2 px-3 py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
            >
              {t.add}
            </button>
          </div>
          <div className="flex flex-wrap mt-2">
            {eventData.genres.map((genre) => (
              <div key={genre} className="bg-gray-700 text-white rounded-full px-3 py-1 m-1 flex items-center">
                {genre}
                <FaTimes className="ml-2 cursor-pointer" onClick={() => handleGenreRemove(genre)} />
              </div>
            ))}
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="dressCode">
            {t.dressCode}
          </label>
          <Select
            id="dressCode"
            name="dressCode"
            value={dressCodes.find((option) => option.value === eventData.dressCode)}
            onChange={(selectedOption) => handleSelectChange(selectedOption, 'dressCode')}
            options={dressCodes}
            className="w-full text-black"
            placeholder={t.selectDressCode}
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="ageRestriction">
            {t.ageRestriction}
          </label>
          <select
            id="ageRestriction"
            name="ageRestriction"
            value={eventData.ageRestriction}
            onChange={handleChange}
            className="w-full px-3 py-2 bg-gray-800 text-white rounded"
          >
            <option value="">{t.allAges}</option>
            <option value="18+">18+</option>
            <option value="21+">21+</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="djs">
            {t.djs}
          </label>
          <div className="flex items-center">
            <input
              type="text"
              id="djs"
              name="djs"
              value={djInput}
              onChange={(e) => setDjInput(e.target.value)}
              className="w-full px-3 py-2 bg-gray-800 text-white rounded"
              onKeyDown={(e) => e.key === 'Enter' && (e.preventDefault(), handleDjAdd())}
            />
            <button
              type="button"
              onClick={handleDjAdd}
              className="ml-2 px-3 py-2 bg-blue-600 text-white rounded hover:bg-blue-500"
            >
              {t.add}
            </button>
          </div>
          <div className="flex flex-wrap mt-2">
            {eventData.djs.map((dj) => (
              <div key={dj} className="bg-gray-700 text-white rounded-full px-3 py-1 m-1 flex items-center">
                {dj}
                <FaTimes className="ml-2 cursor-pointer" onClick={() => handleDjRemove(dj)} />
              </div>
            ))}
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="clubId">
            Club ID
          </label>
          <Select
            id="clubId"
            name="clubId"
            value={venueOptions.find((option) => option.value === eventData.clubId)}
            onChange={(selectedOption) => handleSelectChange(selectedOption, 'clubId')}
            options={venueOptions}
            className="w-full text-black"
            placeholder={t.selectClub}
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm mb-2" htmlFor="type">
            {t.type}
          </label>
          <Select
            id="type"
            name="type"
            value={typeOptions.find((option) => option.value === eventData.type)}
            onChange={(selectedOption) => handleSelectChange(selectedOption, 'type')}
            options={typeOptions}
            className="w-full text-black"
            placeholder={t.selectType}
            required
          />
        </div>
        <div className="mb-4 flex items-center">
          <label className="text-sm mr-2" htmlFor="verified">
            {t.verified}
          </label>
          <input
            type="checkbox"
            id="verified"
            name="verified"
            checked={eventData.verified}
            onChange={handleChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
        </div>
        <div className="mb-4 flex items-center">
          <label className="text-sm mr-2" htmlFor="network">
            {t.network}
          </label>
          <input
            type="checkbox"
            id="network"
            name="network"
            checked={eventData.network}
            onChange={handleChange}
            className="form-checkbox h-5 w-5 text-blue-600"
          />
        </div>
        {/* Activities Management */}
        <div className="mb-6">
          <h3 className="text-xl mb-2">{t.activities}</h3>
          <div className="space-y-2">
            {activities.map((activity: any) => (
              <div
                key={activity._id}
                className="flex justify-between items-center bg-gray-800 p-2 rounded-lg shadow-sm"
              >
                <span className="text-sm">
                  {activity.name} ({activity.points} {t.activityPoints.toLowerCase()})
                </span>
                <div className="flex space-x-1">
                  <button
                    className="px-1 py-1 bg-red-600 rounded hover:bg-red-700"
                    onClick={(e) => {
                      e.preventDefault();
                      handleActivityDelete(activity._id);
                    }}
                  >
                    <FaTimes className="text-xs" />
                  </button>
                  <button
                    className="px-1 py-1 bg-blue-600 rounded hover:bg-blue-700"
                    onClick={(e) => {
                      e.preventDefault();
                      openEditModal(activity);
                    }}
                  >
                    <FaEdit className="text-xs" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* New Activity Form */}
        <div className="mb-4">
          <label className="block text-sm mb-1" htmlFor="activityName">
            {t.activityName}
          </label>
          <div className="flex space-x-2">
            <input
              type="text"
              id="activityName"
              name="name"
              value={newActivity.name}
              onChange={(e) => setNewActivity({ ...newActivity, name: e.target.value })}
              className="w-2/3 px-2 py-1 bg-gray-800 text-white rounded text-sm"
              placeholder={t.activityName}
            />
            <input
              type="number"
              id="activityPoints"
              name="points"
              value={newActivity.points}
              onChange={(e) => setNewActivity({ ...newActivity, points: +e.target.value })}
              className="w-1/3 px-2 py-1 bg-gray-800 text-white rounded text-sm"
              placeholder={t.activityPoints}
            />
          </div>
        </div>

        <button
          type="button"
          onClick={handleActivitySubmit}
          className="px-3 py-1 bg-green-600 text-white rounded hover:bg-green-500 text-sm"
        >
          {t.addActivity}
        </button>

        {/* Modal for Editing Activity */}
        {isEditModalOpen && selectedActivity && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-md w-full">
              <h3 className="text-xl mb-4">{t.editActivity}</h3>
              <div className="mb-4">
                <label className="block text-sm mb-2">{t.activityName}</label>
                <input
                  type="text"
                  name="name"
                  value={selectedActivity.name}
                  onChange={(e) => setSelectedActivity({ ...selectedActivity, name: e.target.value })}
                  className="w-full px-3 py-2 bg-gray-800 text-white rounded"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm mb-2">{t.activityPoints}</label>
                <input
                  type="number"
                  name="points"
                  value={selectedActivity.points}
                  onChange={(e) => setSelectedActivity({ ...selectedActivity, points: +e.target.value })}
                  className="w-full px-3 py-2 bg-gray-800 text-white rounded"
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEditModalOpen(false);
                  }}
                  className="px-3 py-2 bg-gray-600 rounded hover:bg-gray-700"
                >
                  {t.cancel}
                </button>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleActivityEditSubmit();
                  }}
                  className="px-3 py-2 bg-blue-600 rounded hover:bg-blue-700"
                >
                  {t.saveActivity}
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="flex justify-end">
          <button type="submit" className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-500">
            {t.updateEvent}
          </button>
        </div>
      </form>
    </div>
  );
};

export default EventEditor;
