import { APIBase } from 'api/hosts';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ClapSpinner } from 'react-spinners-kit';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import Swal from 'sweetalert2';

const translations = {
  english: {
    register: 'Register',
    username: 'Username',
    email: 'Email Address',
    password: 'Password',
    usernameTaken: 'Username is already taken.',
    emailTaken: 'Email is already in use.',
    passwordRequirements: 'Password Requirements',
    length: '• Between 8 and 15 characters',
    uppercase: '• At least 1 uppercase letter',
    lowercase: '• At least 1 lowercase letter',
    number: '• At least 1 number',
    specialChar: '• At least 1 special character',
    login: 'Login',
    forgotPassword: 'Forgot password?',
    creatingAccount: 'Creating account...',
    invalidPassword: 'Invalid password format.',
    invalidPasswordText: 'Ensure your password meets all the requirements.',
    userRegistered: 'User Registered',
    checkEmail: 'Please check your inbox for an email confirmation.',
    systemError: 'System Error',
    tryAgain: 'Please try again later.',
    networkError: 'Network Error',
    connectionIssue: 'Please check your connection and try again.',
  },
  spanish: {
    register: 'Registrarse',
    username: 'Nombre de Usuario',
    email: 'Correo Electrónico',
    password: 'Contraseña',
    usernameTaken: 'El nombre de usuario ya está en uso.',
    emailTaken: 'El correo electrónico ya está en uso.',
    passwordRequirements: 'Requisitos de la Contraseña',
    length: '• Entre 8 y 15 caracteres',
    uppercase: '• Al menos 1 letra mayúscula',
    lowercase: '• Al menos 1 letra minúscula',
    number: '• Al menos 1 número',
    specialChar: '• Al menos 1 carácter especial',
    login: 'Iniciar Sesión',
    forgotPassword: '¿Olvidaste tu contraseña?',
    creatingAccount: 'Creando cuenta...',
    invalidPassword: 'Formato de contraseña inválido.',
    invalidPasswordText: 'Asegúrate de que tu contraseña cumpla con todos los requisitos.',
    userRegistered: 'Usuario Registrado',
    checkEmail: 'Por favor, revisa tu correo para confirmar tu cuenta.',
    systemError: 'Error del Sistema',
    tryAgain: 'Por favor, inténtalo más tarde.',
    networkError: 'Error de Red',
    connectionIssue: 'Por favor, verifica tu conexión e inténtalo de nuevo.',
  },
  polish: {
    register: 'Rejestracja',
    username: 'Nazwa Użytkownika',
    email: 'Adres Email',
    password: 'Hasło',
    usernameTaken: 'Nazwa użytkownika jest już zajęta.',
    emailTaken: 'Adres e-mail jest już używany.',
    passwordRequirements: 'Wymagania dotyczące hasła',
    length: '• Od 8 do 15 znaków',
    uppercase: '• Co najmniej 1 wielka litera',
    lowercase: '• Co najmniej 1 mała litera',
    number: '• Co najmniej 1 cyfra',
    specialChar: '• Co najmniej 1 znak specjalny',
    login: 'Zaloguj się',
    forgotPassword: 'Zapomniałeś hasła?',
    creatingAccount: 'Tworzenie konta...',
    invalidPassword: 'Nieprawidłowy format hasła.',
    invalidPasswordText: 'Upewnij się, że hasło spełnia wszystkie wymagania.',
    userRegistered: 'Użytkownik Zarejestrowany',
    checkEmail: 'Sprawdź swoją skrzynkę odbiorczą, aby potwierdzić konto.',
    systemError: 'Błąd systemu',
    tryAgain: 'Spróbuj ponownie później.',
    networkError: 'Błąd sieci',
    connectionIssue: 'Sprawdź swoje połączenie i spróbuj ponownie.',
  },
};

const Register: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false); // State for toggling password visibility
  const [usernameTaken, setUsernameTaken] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false,
  });
  const { language } = useLanguage(); // Language context

  const navigate = useNavigate();
  const t = translations[language as keyof typeof translations];

  const handleChangeUsername = async (e: ChangeEvent<HTMLInputElement>) => {
    const intendedUsername = e.target.value;
    setUsername(intendedUsername);
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json',
      },
    };
    const response = await fetch(`${APIBase}/auth/usernameSlot/${intendedUsername}`, data);
    setUsernameTaken(!response.ok);
  };

  const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPassword(value);

    setPasswordCriteria({
      length: value.length >= 8 && value.length <= 15,
      uppercase: /[A-Z]/.test(value),
      lowercase: /[a-z]/.test(value),
      number: /[0-9]/.test(value),
      specialChar: /[^a-zA-Z0-9]/.test(value),
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
  
    if (Object.values(passwordCriteria).some((criteria) => !criteria)) {
      Swal.fire({
        title: t.invalidPassword,
        text: t.invalidPasswordText,
        showConfirmButton: false,
        background: '#000000',
        color: '#FFFFFF',
      });
      setLoading(false);
      return;
    }
  
    Swal.fire({
      title: t.creatingAccount,
      showConfirmButton: false,
      background: '#000000',
      color: '#FFFFFF',
    });
  
    const body = { username, email, password };
    const data: RequestInit = {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };
  
    try {
      const response = await fetch(`${APIBase}/auth/register`, data);
      const result = await response.json();
  
      if (!response.ok) {
        if (result.type === 'username-taken') {
          Swal.fire({
            title: t.usernameTaken,
            text: `${t.username}: ${username}`,
            icon: 'error',
            background: '#000000',
            color: '#FFFFFF',
          });
        } else if (result.type === 'email-taken') {
          Swal.fire({
            title: t.emailTaken,
            text: `${t.email}: ${email}`,
            icon: 'error',
            background: '#000000',
            color: '#FFFFFF',
          });
        } else {
          Swal.fire({
            title: t.systemError,
            text: t.tryAgain,
            icon: 'error',
            background: '#000000',
            color: '#FFFFFF',
          });
        }
        setLoading(false);
        return;
      }
  
      navigate('/auth/login');
      Swal.fire({
        title: t.userRegistered,
        text: t.checkEmail,
        icon: 'success',
        showConfirmButton: false,
        timer: 3000,
        background: '#000000',
        color: '#FFFFFF',
      });
    } catch {
      Swal.fire({
        title: t.networkError,
        text: t.connectionIssue,
        icon: 'error',
        background: '#000000',
        color: '#FFFFFF',
      });
    } finally {
      setLoading(false);
    }
  };
  
  

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black p-6">
      <div className="w-full max-w-sm bg-black bg-opacity-70 rounded-lg shadow-md p-6">
        <h1 className="text-4xl text-white font-bold mb-6">{t.register}</h1>
        <form onSubmit={handleSubmit} className="space-y-4">
          {usernameTaken && <p className="text-red-500 text-sm">{t.usernameTaken}</p>}
          <input
            type="text"
            placeholder={t.username}
            value={username}
            onChange={handleChangeUsername}
            className="w-full px-3 py-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none"
            required
          />
          <input
            type="email"
            placeholder={t.email}
            value={email}
            onChange={handleChangeEmail}
            className="w-full px-3 py-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none"
            required
          />
          <div className="relative w-full">
            <input
              type={passwordVisible ? 'text' : 'password'}
              placeholder={t.password}
              value={password}
              onChange={handleChangePassword}
              className="w-full px-3 py-2 rounded bg-gray-700 text-white placeholder-gray-400 focus:outline-none"
              required
            />
            <button
              type="button"
              onClick={() => setPasswordVisible(!passwordVisible)}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white focus:outline-none"
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          <div className="text-gray-400 text-sm mb-4">
            <p className="mb-2">{t.passwordRequirements}:</p>
            <ul className="space-y-1">
              <li className={passwordCriteria.length ? 'text-green-400' : ''}>{t.length}</li>
              <li className={passwordCriteria.uppercase ? 'text-green-400' : ''}>{t.uppercase}</li>
              <li className={passwordCriteria.lowercase ? 'text-green-400' : ''}>{t.lowercase}</li>
              <li className={passwordCriteria.number ? 'text-green-400' : ''}>{t.number}</li>
              <li className={passwordCriteria.specialChar ? 'text-green-400' : ''}>{t.specialChar}</li>
            </ul>
          </div>
          <button
            type="submit"
            className="w-full py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700 flex items-center justify-center"
          >
            {loading ? <ClapSpinner size={15} color="#FFFFFF" /> : t.register}
          </button>
        </form>
        <div className="flex justify-between mt-4">
          <Link to="/auth/login" className="text-sm text-gray-400 hover:text-white">
            {t.login}
          </Link>
          <Link to="/auth/recover" className="text-sm text-gray-400 hover:text-white">
            {t.forgotPassword}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
