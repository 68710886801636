import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from 'Contexts/Auth';
import { APIBase } from 'api/hosts';
import { FaDoorOpen, FaChevronLeft } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { useLanguage } from 'Contexts/LanguageContext';

const translations = {
  english: {
    rooms: 'Rooms',
    back: 'Back',
    capacity: 'people capacity',
    noRooms: 'No rooms available at the moment.',
    error: 'Error',
    fetchError: 'Could not load rooms. Please try again later.',
  },
  spanish: {
    rooms: 'Habitaciones',
    back: 'Volver',
    capacity: 'capacidad para personas',
    noRooms: 'No hay habitaciones disponibles en este momento.',
    error: 'Error',
    fetchError: 'No se pudieron cargar las habitaciones. Inténtalo de nuevo más tarde.',
  },
  polish: {
    rooms: 'Pokoje',
    back: 'Powrót',
    capacity: 'osób',
    noRooms: 'Brak dostępnych pokoi w tej chwili.',
    error: 'Błąd',
    fetchError: 'Nie udało się załadować pokoi. Spróbuj ponownie później.',
  },
};

const RoomsList: React.FC = () => {
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage(); // Language context
  const t = translations[language as keyof typeof translations]; // Translations based on the current language
  const { venueId } = useParams<{ venueId: string }>();
  const [rooms, setRooms] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRooms();
  }, [venueId]);

  const fetchRooms = async () => {
    try {
      const response = await fetch(`${APIBase}/client/room/byVenueId/${venueId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setRooms(data.rooms);
      } else {
        throw new Error(t.fetchError);
      }
    } catch (error) {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <div className="text-white min-h-screen bg-gradient-to-b from-black via-gray-900 to-black p-4 pt-20 pb-24 md:pb-28">
      {/* Back Button */}
      <button
        onClick={() => navigate(-1)}
        className="fixed top-20 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out"
      >
        <FaChevronLeft className="text-xl" />
      </button>

      {/* Page Content */}
      <div className="max-w-3xl mx-auto mt-16">
        <h2 className="text-3xl font-bold mb-6 text-center">{t.rooms}</h2>

        {/* Rooms List */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {rooms.length > 0 ? (
            rooms.map((room) => (
              <div
                key={room._id}
                className="bg-gray-800 p-4 rounded-lg shadow-lg flex flex-col items-center cursor-pointer hover:bg-gray-700 transition-all duration-300 ease-in-out"
                onClick={() => navigate(`/app/partyticket/room/${room._id}`)}
              >
                <FaDoorOpen className="text-green-400 text-4xl mb-4" />
                <h4 className="text-lg font-semibold text-center">{room.name}</h4>
                <p className="text-sm text-gray-400 text-center">{room.capacity} {t.capacity}</p>
              </div>
            ))
          ) : (
            <p className="text-gray-400 text-center col-span-full">{t.noRooms}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoomsList;
